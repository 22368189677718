import clsx from 'clsx';

import styles from './Avatar.module.scss';

interface IOn3AvatarProps {
  /** Specifies maximum height of the image in pixels. Exact behavior depends on the fit mode (described below). */
  height?: number;
  /** Specifies maximum width of the image in pixels. Exact behavior depends on the fit mode (described below). */
  width?: number;
  /** Specifies quality for images in JPEG, WebP and AVIF formats. The quality is in 1-100 scale, but useful values are between 50 (low quality, small file size) and 90 (high quality, large file size). 85 is the default. When using the PNG format, an explicit quality setting allows use of PNG8 (palette) variant of the format. */
  quality?: number | null;
  /** Affects interpretation of width and height. All resizing modes preserve aspect ratio. Available modes are: */
  fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad' | null;
  /** When cropping with fit=cover, specifies the most important side or point in the image that shouldn't be cropped off. */
  gravity?: string | null;
  className?: string | null;
  altText?: string | null;
  title?: string | null;
  source?: string | null;
  domain?: string | null;
  loading?: 'lazy' | 'eager';
  type?: string | null;
  teamAvatar?: boolean;
}

export const Avatar = ({
  height = 90,
  width = 90,
  quality,
  fit,
  gravity,
  altText = 'Avatar',
  source,
  title,
  domain,
  className,
  loading = 'lazy',
  type,
  teamAvatar,
}: IOn3AvatarProps) => {
  const isSvg = type === 'svg';
  const options = [`height=${height}`, `width=${width}`];

  if (quality) {
    options.push(`quality=${quality}`);
  }

  if (fit) {
    options.push(`fit=${fit}`);
  }

  if (gravity) {
    options.push(`gravity=${gravity}`);
  }

  const defaultSource = teamAvatar
    ? 'https://on3static.com/teams/default.svg'
    : `https://on3static.com/cdn-cgi/image/height=${height},width=${width}/static/on3/avatar.png`;

  if (!source) {
    return (
      <img
        alt={altText || 'Default Avatar'}
        className={clsx(styles.avatar, className, {
          [styles.teamAvatar]: teamAvatar,
        })}
        height={height}
        loading="lazy"
        src={defaultSource}
        title={title ?? undefined}
        width={width}
      />
    );
  }

  const path = source?.replace(/^\//, '') || 'static/on3/avatar.png';
  const newDomain = `https://${domain || 'on3static.com'}/`;

  let newSource = '';

  if (!domain) {
    newSource = source;
  } else if (domain !== 'on3static.com') {
    newSource = `https://${domain}/${source}`;
  } else {
    newSource =
      options.length === 0 || isSvg
        ? `${newDomain}${path}`
        : `${newDomain}cdn-cgi/image/${options.join(',')}/${path}`;
  }

  return (
    <img
      alt={altText || 'Default Avatar'}
      className={clsx(styles.avatar, className, {
        [styles.teamAvatar]: teamAvatar,
      })}
      height={height}
      loading={loading}
      src={newSource}
      title={title ?? undefined}
      width={width}
    />
  );
};
