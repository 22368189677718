import { Check } from '@mui/icons-material';
import { On3Recruit } from '@on3/ui-lib/components/Svg/On3Recruit';
import clsx from 'clsx';
import { useModal } from 'components/Modals';
import {
  PhotoForm,
  SportOrgForm,
} from 'components/Modals/Dashboard/CompleteProfile/Forms';
import { usePlayerProfile } from 'contexts/PlayerProfile/PlayerProvider';
import { useCallback, useMemo, useState } from 'react';

import styles from './CompleteProfile.module.scss';

export const CompleteProfileModal = () => {
  const {
    state: { adminPlayer },
  } = usePlayerProfile();
  const { closeModal } = useModal();
  const [screen, setScreen] = useState(0);

  const screens = useMemo(() => {
    const s = [];

    if (!adminPlayer?.highSchool || !adminPlayer?.defaultPersonSport) {
      s.push({
        label: 'General*',
        component: SportOrgForm,
      });
    }

    if (!adminPlayer?.defaultAsset) {
      s.push({
        label: 'Profile Photo',
        component: PhotoForm,
      });
    }

    return s;
  }, [adminPlayer]);

  const onComplete = useCallback(() => {
    if (screen === screens.length - 1) {
      closeModal();

      window.location.reload();

      return;
    }

    setScreen((prev) => prev + 1);
  }, [closeModal, screen, screens.length]);

  const Form = screens[screen]?.component;

  if (!screens.length) return null;

  return (
    <div className={styles.modal}>
      <header className={styles.header}>
        <On3Recruit className={styles.logo} variant="black" />
        <h2>Let&apos;s get started!</h2>
        <p>
          You want your profile to stand out, so let&apos;s get some initial
          info to get your profile started.
        </p>
        <nav className={styles.nav}>
          <ul>
            {screens.map((item, index) => (
              <li key={index}>
                <span
                  className={clsx({
                    [styles.active]: index === screen,
                  })}
                >
                  {index < screen && <Check className={styles.checkmark} />}
                  {item.label}
                </span>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <div className={styles.form}>
        <Form onComplete={onComplete} />
      </div>
    </div>
  );
};
