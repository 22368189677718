import { RecruitsReceiptModal } from 'components/Modals/Piano/RecruitsReceipt';
import { ComponentProps } from 'react';

export type PianoModalType = 'piano.recruits-receipt';

export interface PianoModalPropsMap {
  'piano.recruits-receipt': ComponentProps<typeof RecruitsReceiptModal>;
}

export const pianoModals = {
  'piano.recruits-receipt': RecruitsReceiptModal,
};
