export const isDiff = <T>(
  original?: T | null,
  current?: T | null,
  path?: string,
): boolean => {
  if (!path) return JSON.stringify(original) !== JSON.stringify(current);

  const getValue = (obj: any, keys: string[]): any =>
    keys.reduce((acc, key) => {
      if (acc === undefined || acc === null) return undefined;

      return !isNaN(Number(key)) ? acc[Number(key)] : acc[key];
    }, obj);

  const keys = path.split('.');
  const originalValue = getValue(original, keys);
  const currentValue = getValue(current, keys);

  return JSON.stringify(originalValue) !== JSON.stringify(currentValue);
};
