export const filterNumber = (value: string) => {
  return value.replace(/[^0-9.]/g, '');
};

export const filterBirthdate = (birthdate: string) => {
  return birthdate.replace(/[^0-9]/g, '').slice(0, 8);
};

export const filterPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/[^0-9]/g, '').slice(0, 10);
};
