import {
  type OrganizationType,
  type PublicOrganizationSearchResponse,
  rdbV1OrganizationsAdvancedSearchList,
  type RdbV1OrganizationsAdvancedSearchListParams,
} from '@on3/api';
import type { AutoCompleteProps } from '@on3/ui-lib/src/components/AutoComplete/AutoComplete';
import {
  AutoComplete,
  type OptionType,
} from '@on3/ui-lib/src/components/AutoComplete/AutoComplete';
import { debounce } from '@on3/ui-lib/src/utils/debounce';
import { externalApi } from '@on3/ui-lib/utils/api';
import { useCallback, useState } from 'react';

interface OrganizationAutoCompleteProps
  extends Omit<AutoCompleteProps, 'options' | 'onChange' | 'type'> {
  type: OrganizationType;
  name: string;
  onChange: (value: OptionType[], field?: string) => void;
  onSearch?: (value: string) => void;
  optionTemplate?: (org: PublicOrganizationSearchResponse) => OptionType;
}

export const OrganizationAutoComplete = ({
  type,
  label = 'Organization',
  name,
  limit = 10,
  optionTemplate,
  onChange = () => {},
  onSearch = () => {},
  ...props
}: OrganizationAutoCompleteProps) => {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Memoize the onChange handler to prevent unnecessary re-renders
  const handleOnChange = useCallback(
    (value: OptionType[]) => onChange(value, name),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSearch = useCallback(
    async (v: string) => {
      const query = {
        searchText: v,
        organizationType: type,
      } as RdbV1OrganizationsAdvancedSearchListParams;

      try {
        const response = await rdbV1OrganizationsAdvancedSearchList(
          externalApi,
          query,
        );

        if (!response.list) {
          console.error('Failed to fetch organization results');

          return;
        }

        setOptions(
          response.list.map((org) => {
            if (optionTemplate) {
              return optionTemplate(org);
            }

            const key = org.key;
            const optionLabel = `${org.fullName}${
              org.city?.name || org.city?.state?.abbreviation
                ? ` (${[org.city?.name, org.city?.state?.abbreviation]
                    .filter(Boolean)
                    .join(', ')})`
                : ''
            }`;
            const selectedLabel = org.fullName || org.name;
            const value = org.key;

            return {
              key,
              label: optionLabel,
              selectedLabel,
              value,
            };
          }),
        );
      } catch (e) {
        console.error('Error fetching organizations:', e);
      } finally {
        setLoading(false);
      }
    },
    [optionTemplate, type],
  );

  const handleOnSearch = debounce((v: string) => {
    onSearch(v);

    if (v.length < 3) {
      setOptions([]);

      return;
    }

    setLoading(true);
    handleSearch(v);
  }, 500);

  return (
    <AutoComplete
      {...props}
      label={label}
      limit={limit}
      loading={loading}
      onChange={handleOnChange}
      onSearch={handleOnSearch}
      options={options}
    />
  );
};
