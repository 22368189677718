import { SpinnerThird } from '../Svg/SpinnerThird';
import styles from './Spinner.module.scss';

export const Spinner = () => {
  return (
    <div className={styles.base}>
      <SpinnerThird />
    </div>
  );
};
