import { SVGProps } from 'react';

const SvgTikTok = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '15'}
    height={props.height || '16'}
    viewBox="0 0 15 16"
    fill={props.fill || '#000'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4411 0.653229V0.666001C10.4411 0.899551 10.511 4.2769 14.0828 4.48673C14.0828 7.62871 14.0846 4.48673 14.0846 7.09593C13.8161 7.11235 11.7322 6.96273 10.4374 5.81505L10.4337 10.8948C10.465 13.1956 9.17567 15.4508 6.75892 15.9051C6.08208 16.0329 5.47145 16.0475 4.44884 15.8249C-1.44776 14.0787 0.511031 5.40269 6.43153 6.33141C6.43153 9.13219 6.43337 6.33141 6.43337 9.13219C3.98719 8.77457 3.17057 10.7926 3.81982 12.2377C4.41205 13.5532 6.84536 13.8379 7.69509 11.9822C7.79073 11.6191 7.83855 11.2049 7.83855 10.7397V0.653229H10.4411Z"
    />
  </svg>
);

export default SvgTikTok;
