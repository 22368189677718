import { InfoRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
  MoneyWantedResponse,
  NilDashboardValuationBreakdownResponse,
  v1NilMoneyWantedCreate,
} from '@on3/api';
import { On3IconCheck } from '@on3/icons';
import { externalApi, swrFetcher } from '@on3/ui-lib/index';
import {
  currencyFormatterCommas,
  formatPhoneNumber,
} from '@on3/ui-lib/utils/formatters';
import { useModal } from 'components/Modals';
import { Text } from 'components/UI/Typography/Text';
import { usePlayerProfile } from 'contexts/PlayerProfile/PlayerProvider';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useSWR from 'swr';

import styles from './MoneyWanted.module.scss';

interface MoneyWantedModalProps {
  playerId: string | number;
}

const bullets = [
  `Amount is private — can only be seen by schools`,
  `You can change your amount wanted any time`,
  `Amounts are not final deals`,
  `Will help your negotiations with schools`,
];

export const MoneyWantedModal = ({ playerId }: MoneyWantedModalProps) => {
  const { closeModal } = useModal();
  const { setState } = usePlayerProfile();

  const { data: moneyWanted } = useSWR<MoneyWantedResponse>(
    `/rdb/v1/people/${playerId}/nil/money-wanted`,
    swrFetcher,
  );

  const { data: valuationBreakdown } =
    useSWR<NilDashboardValuationBreakdownResponse>(
      `/rdb/v1/people/${playerId}/nil-dashboard-valuation-breakdown`,
      swrFetcher,
    );

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      monthly: 0,
      annually: 0,
      name: '',
      phoneNumber: '',
    },
  });

  const [error, setError] = useState<Error | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (moneyWanted) {
      setValue('monthly', Math.round(moneyWanted.annualMoneyWanted / 12));
      setValue('annually', moneyWanted.annualMoneyWanted);
      setValue('name', moneyWanted.agentInfo?.name || '');
      setValue('phoneNumber', moneyWanted.agentInfo?.phoneNumber || '');
    }
  }, [moneyWanted, setValue]);

  /**
   * Submit form
   */
  async function onSubmit() {
    const values = getValues();

    const body = {
      personKey: +playerId,
      annualWanted: values.annually,
      agentInfo: {
        name: values.name,
        phoneNumber: values.phoneNumber,
      },
    };

    await v1NilMoneyWantedCreate(externalApi, body)
      .then((res) => {
        setState((p) => ({
          ...p,
          moneyWanted: res,
        }));
        setIsSubmitted(true);
      })
      .catch((err) => {
        setError(err);
      });
  }

  /**
   * Handle money input changes
   */
  function handleMoneyChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;

    const filteredValue = value.replace(/[^0-9]/g, '');

    let monthly;
    let annually;

    if (name === 'monthly') {
      monthly = Number(filteredValue);
      annually = Math.round(monthly * 12);
    } else {
      annually = Number(filteredValue);
      monthly = Math.round(annually / 12);
    }

    setValue('monthly', monthly);
    setValue('annually', annually);
  }

  /**
   * Handle agent input changes
   */
  function handleAgentChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;

    let filteredValue = value;

    if (name === 'phoneNumber') {
      filteredValue = value.replace(/[^0-9]/g, '');

      if (filteredValue.length > 10) {
        return;
      }
    }

    setValue(name as 'name' | 'phoneNumber', filteredValue);
  }

  return (
    <div className={styles.modal}>
      <Text variant="h2">{`$ Wanted`}</Text>
      {!isSubmitted && (
        <>
          <p className={styles.subheading}>
            Set a minimum amount that you would like to receive from the school
            of your choice.
          </p>
          <ul>
            {bullets.map((bullet, index) => (
              <li key={index}>
                <div className={styles.check}>
                  <On3IconCheck />
                </div>
                {bullet}
              </li>
            ))}
          </ul>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <h3>Minimum amounts accepted</h3>
            <div>
              <label htmlFor="wantedMonthly">
                <span>Monthly</span>
                <div className={styles.moneyInput}>
                  <Controller
                    control={control}
                    name="monthly"
                    render={({ field: { onChange, value, ...field } }) => (
                      <input
                        {...field}
                        id="wantedMonthly"
                        onChange={handleMoneyChange}
                        type="text"
                        value={value.toLocaleString()}
                      />
                    )}
                  />
                </div>
              </label>
            </div>
            <div>
              <label htmlFor="wantedAnnual">
                <span>Annually</span>
                <div className={styles.moneyInput}>
                  <Controller
                    control={control}
                    name="annually"
                    render={({ field: { onChange, value, ...field } }) => (
                      <input
                        {...field}
                        id="wantedAnnual"
                        onChange={handleMoneyChange}
                        type="text"
                        value={value.toLocaleString()}
                      />
                    )}
                  />
                </div>
              </label>
              {valuationBreakdown?.valuation && (
                <div className={styles.valuation}>
                  <InfoRounded />
                  <p>{`On3 Annual NIL Valuation: ${currencyFormatterCommas(
                    valuationBreakdown.valuation,
                  )}`}</p>
                </div>
              )}
            </div>
            <div>
              <label htmlFor="agentName">
                <span>Agent Name</span>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, value, ...field } }) => (
                    <input
                      {...field}
                      id="agentName"
                      onChange={handleAgentChange}
                      type="text"
                      value={value}
                    />
                  )}
                />
              </label>
            </div>
            <div>
              <label htmlFor="agentPhoneNumber">
                <span>Agent Phone Number</span>
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field: { onChange, value, ...field } }) => (
                    <input
                      {...field}
                      id="agentPhoneNumber"
                      onChange={handleAgentChange}
                      type="text"
                      value={formatPhoneNumber(value)}
                    />
                  )}
                />
              </label>
            </div>

            {error && <p className={styles.error}>{error.message}</p>}

            <div className={styles.submit}>
              <Button
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                Update
              </Button>
            </div>
          </form>
        </>
      )}

      {isSubmitted && (
        <div className={styles.success}>
          <p>Your amounts have been updated.</p>
          <Button onClick={closeModal} variant="contained">
            Close
          </Button>
        </div>
      )}
    </div>
  );
};
