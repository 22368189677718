import {
  EditType,
  type HighSchoolProfileEdit,
  OrganizationType,
  PositionType,
  type SportPlayedProfileEdit,
  v1PositionsList,
  v1SportsList,
} from '@on3/api';
import { type OptionType } from '@on3/ui-lib/components/AutoComplete/AutoComplete';
import { OrganizationAutoComplete } from '@on3/ui-lib/components/AutoComplete/OrganizationAutoComplete';
import { Form } from '@on3/ui-lib/components/Form';
import { Select } from '@on3/ui-lib/components/Select/Select';
import { externalApi, useToast } from '@on3/ui-lib/index';
import { baseSports } from '@on3/ui-lib/store/mocks/sports';
import { settleSequentially } from '@on3/ui-lib/utils/api';
import { yearOptions } from '@on3/ui-lib/utils/date';
import { updatePath } from '@on3/ui-lib/utils/updatePath';
import { yup } from '@on3/ui-lib/utils/yup';
import { usePlayerProfile } from 'contexts/PlayerProfile/PlayerProvider';
import { useEffect, useState } from 'react';
import { createUpdateFn } from 'utils/player/admin';

import styles from './Forms.module.scss';

interface ISportOrgRequest {
  highSchool: HighSchoolProfileEdit;
  sportPlayed: SportPlayedProfileEdit;
}

const schema = yup.object({
  highSchool: yup.object().shape({
    organizationKey: yup.number().required().label('High School'),
    organizationName: yup.string().required().label('High School'),
  }),
  sportPlayed: yup.object().shape({
    sportKey: yup.number().required().label('Sport'),
    firstYear: yup.number().required().label('Year started'),
    primaryPositionKey: yup.number().required().label('Position'),
  }),
});

export const SportOrgForm = ({ onComplete }: { onComplete: () => void }) => {
  const {
    state: { player, adminPlayer },
  } = usePlayerProfile();
  const { notify } = useToast();

  const [values, setValues] = useState<ISportOrgRequest>({
    highSchool: {
      $type: 'highSchool',
      editType: EditType.Add,
      organizationKey: adminPlayer?.highSchool?.key ?? 0,
      organizationName: adminPlayer?.highSchool?.name || '',
    },
    sportPlayed: {
      $type: 'sportPlayed',
      editType: EditType.Add,
      sportKey: adminPlayer?.defaultPersonSport?.sportKey || 0,
      primaryPositionKey: null,
      firstYear: null,
    },
  });

  const [sportOptions, setSportOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [positionOptions, setPositionOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    v1SportsList(externalApi)
      .then((sports) => {
        setSportOptions(
          sports.map((s) => ({
            label: s.name || '',
            value: String(s.key),
          })),
        );
      })
      .catch(() => {
        setSportOptions(
          baseSports.map((s) => ({
            label: s.name || '',
            value: String(s.key),
          })),
        );
      });
  }, []);

  useEffect(() => {
    if (!values.sportPlayed.sportKey) return;
    setPositionOptions([]);

    try {
      v1PositionsList(externalApi, {
        positionType: PositionType.Player,
        sportKey: values.sportPlayed.sportKey,
      }).then((res) =>
        setPositionOptions(
          res.map((p) => ({ label: p.name || '', value: String(p.key) })),
        ),
      );
    } catch (error) {
      notify('Unable to fetch positions', { type: 'error' });
    }
  }, [notify, values.sportPlayed]);

  const handleSchoolChange = (school: OptionType[]) => {
    setValues((curr) =>
      updatePath(curr, 'highSchool', {
        ...curr.highSchool,
        organizationKey: school[0]?.key ?? 0,
        organizationName: school[0]?.selectedLabel ?? '',
      }),
    );
  };

  const handleUpdate = (
    key: KeyPath<ISportOrgRequest>,
    value: string | number | null,
  ) => {
    setValues((curr) => updatePath(curr, key, value));
  };

  const handleSubmit = async (data: ISportOrgRequest) => {
    try {
      const orgUpdate = createUpdateFn(
        externalApi,
        data.highSchool,
        player.key,
      );
      const sportUpdate = createUpdateFn(
        externalApi,
        data.sportPlayed,
        player.key,
      );

      const updates = [orgUpdate, sportUpdate];

      const responses = await settleSequentially(updates, true);

      if (responses.every((r) => r.status === 'rejected')) {
        notify('Failed to update profile details. Please try again.', {
          type: 'error',
        });

        return;
      }

      if (responses.some((r) => r.status === 'rejected')) {
        notify('Some profile updates failed to submit.', {
          type: 'warning',
        });

        return;
      }

      onComplete();
    } catch (error) {
      notify('Oops, we were unable to save your info. Please try again.', {
        type: 'error',
      });
    }
  };

  const canSubmit =
    !!values.highSchool.organizationKey &&
    !!values.sportPlayed.sportKey &&
    !!values.sportPlayed.firstYear &&
    !!values.sportPlayed.primaryPositionKey;

  return (
    <Form
      className={styles.form}
      onSubmit={handleSubmit}
      schema={schema}
      values={values}
    >
      {({ errors, processing }) => (
        <>
          <OrganizationAutoComplete
            id="highSchool"
            label="Which high school do you attend?*"
            limit={1}
            name="highSchool"
            onChange={handleSchoolChange}
            onSearch={(v) => handleUpdate('highSchool.organizationName', v)}
            type={OrganizationType.HighSchool}
            value={[
              {
                key: values.highSchool.organizationKey ?? 0,
                label: values.highSchool.organizationName ?? '',
              },
            ].filter((o) => o.key)}
          />
          <Select
            error={errors.sportKey}
            label="What sport do you play?"
            name="sportKey"
            onChange={(e) =>
              handleUpdate(
                'sportPlayed.sportKey',
                e.target.value ? Number(e.target.value) : null,
              )
            }
            options={sportOptions}
            placeholder="Select a sport"
            required
            value={String(values.sportPlayed.sportKey ?? '')}
          />
          <Select
            error={errors.firstYear}
            label="When did you start playing?*"
            onChange={(e) =>
              handleUpdate(
                'sportPlayed.firstYear',
                e.target.value ? Number(e.target.value) : null,
              )
            }
            options={[{ label: 'Select a year', value: '' }].concat(
              yearOptions,
            )}
            value={String(values.sportPlayed.firstYear ?? '')}
          />
          <Select
            disabled={
              !values.sportPlayed.sportKey || positionOptions.length === 0
            }
            error={errors.primaryPositionKey}
            label="What position are you?"
            name="primaryPositionKey"
            onChange={(e) =>
              handleUpdate(
                'sportPlayed.primaryPositionKey',
                e.target.value ? Number(e.target.value) : null,
              )
            }
            options={positionOptions}
            placeholder="Select a position"
            required
            value={String(values.sportPlayed.primaryPositionKey ?? '')}
          />
          <footer>
            <button disabled={processing || !canSubmit} type="submit">
              {processing ? 'Saving...' : 'Continue'}
            </button>
          </footer>
        </>
      )}
    </Form>
  );
};
