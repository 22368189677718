import { ChallengeValuationModal } from 'components/Modals/Dashboard/ChallengeValuation';
import { CompleteProfileModal } from 'components/Modals/Dashboard/CompleteProfile';
import { MoneyWantedModal } from 'components/Modals/Dashboard/MoneyWanted';
import { UpdateAvatarModal } from 'components/Modals/Dashboard/UpdateAvatar';
import { type ComponentProps } from 'react';

export type DashboardModalType =
  | 'dashboard.challenge-valuation'
  | 'dashboard.complete-profile'
  | 'dashboard.money-wanted'
  | 'dashboard.update-avatar';

export interface DashboardModalPropsMap {
  'dashboard.challenge-valuation': ComponentProps<
    typeof ChallengeValuationModal
  >;
  'dashboard.complete-profile': ComponentProps<typeof CompleteProfileModal>;
  'dashboard.money-wanted': ComponentProps<typeof MoneyWantedModal>;
  'dashboard.update-avatar': ComponentProps<typeof UpdateAvatarModal>;
}

export const dashboardModals = {
  'dashboard.challenge-valuation': ChallengeValuationModal,
  'dashboard.complete-profile': CompleteProfileModal,
  'dashboard.money-wanted': MoneyWantedModal,
  'dashboard.update-avatar': UpdateAvatarModal,
};
