import type {
  CurrentSportYearRecord,
  MenuResponse,
  SiteResponse,
  SlimSiteResponse,
  SportFilter,
} from '@on3/api';
import React, { createContext, useContext, useMemo } from 'react';

import { PageType } from '../types/common-props';

export interface SiteServerProps {
  currentSite: SiteResponse;
  defaultYears: CurrentSportYearRecord;
  menuData: MenuResponse[] | null;
  rankingSports?: SportFilter[] | null;
  scheduleSports?: SportFilter[] | null;
  secondaryMenuData: MenuResponse[] | null;
  siblingSite?: SiteResponse | null;
  siteUrls: SlimSiteResponse[];
  sportsData: SportFilter[];
}

export interface ISiteContext
  extends Omit<
    SiteServerProps,
    'rankingSports' | 'scheduleSports' | 'siblingSite'
  > {
  pageType: PageType;
}

const defaultContext: ISiteContext = {
  siteUrls: [],
  currentSite: {} as SiteResponse,
  menuData: null,
  secondaryMenuData: null,
  sportsData: [],
  defaultYears: {} as CurrentSportYearRecord,
  pageType: 'other',
};

const SiteContext = createContext<ISiteContext>(defaultContext);

interface ISiteContextProps extends ISiteContext {
  children: React.ReactNode;
}

export const SiteProvider = ({
  children,
  ...contextProps
}: ISiteContextProps) => {
  const contextValue = useMemo(() => contextProps, [contextProps]);

  return (
    <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>
  );
};

export const useSite = (): ISiteContext => useContext(SiteContext);
