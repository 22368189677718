import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { NilDisputeType, v1NilDisputeRequestCreate } from '@on3/api';
import { externalApi } from '@on3/ui-lib/index';
import { useModal } from 'components/Modals';
import { Text } from 'components/UI/Typography/Text';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import styles from './ChallengeValuation.module.scss';

interface ChallengeValuationModalProps {
  playerId: string | number;
}

enum DisputeReasons {
  MakingMore = 'I am currently making more than listed',
  AdditionalOffers = 'I have offers from schools for higher roster $',
  Other = 'Other',
}

const reasons = [
  {
    label: DisputeReasons.MakingMore,
    value: NilDisputeType.MakingMore,
    otherLabel: 'Specify how much you are making',
  },
  {
    label: DisputeReasons.AdditionalOffers,
    value: NilDisputeType.AdditionalOffers,
    otherLabel: 'How much is your highest offer?',
  },
  {
    label: DisputeReasons.Other,
    value: NilDisputeType.Other,
    otherLabel: 'Enter your reason for challenging',
  },
];

type FormValues = {
  type: NilDisputeType;
  otherInformation: string;
  error: string;
};

const schema = yup.object().shape({
  type: yup.string().required('Please select a reason'),
  otherInformation: yup.string().required('Please provide more information'),
});

export const ChallengeValuationModal = ({
  playerId,
}: ChallengeValuationModalProps) => {
  const { closeModal } = useModal();

  const {
    control,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit,
    getValues,
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: '' as NilDisputeType,
      otherInformation: '',
    },
  });

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const selectedReason = watch('type');

  /**
   * Submit form
   */
  async function onSubmit() {
    const values = getValues();

    const body = {
      personKey: +playerId,
      type: values.type as NilDisputeType,
      otherInformation: values.otherInformation,
    };

    await v1NilDisputeRequestCreate(externalApi, body)
      .then((res) => {
        setIsSubmitted(true);
      })
      .catch(() => {
        setError('error', {
          type: 'manual',
          message:
            'We are unable to process your request at this time. Please try again later.',
        });
      });
  }

  return (
    <div className={styles.modal}>
      <Text variant="h2">Challenge My Valuation</Text>
      {!isSubmitted && (
        <>
          <p>Do you think your valuation should be higher?</p>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="challengeReason">
                <span>Reason for Challenge</span>
                <div>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field: { value, ...field } }) => (
                      <select {...field} id="challengeReason" value={value}>
                        <option disabled value="">
                          Select Reason
                        </option>
                        {Object.values(reasons).map((reason, idx) => (
                          <option key={idx} value={reason.value}>
                            {reason.label}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
              </label>
            </div>
            {selectedReason && (
              <div>
                <label htmlFor="otherInformation">
                  <span>
                    {
                      reasons.find((reason) => reason.value === selectedReason)
                        ?.otherLabel
                    }
                  </span>
                  <div>
                    <Controller
                      control={control}
                      name="otherInformation"
                      render={({ field }) =>
                        selectedReason === NilDisputeType.Other ? (
                          <textarea {...field} id="otherInformation" />
                        ) : (
                          <input {...field} id="otherInformation" type="text" />
                        )
                      }
                    />
                  </div>
                </label>
                {errors.otherInformation && (
                  <p className={styles.error}>
                    {errors.otherInformation.message}
                  </p>
                )}
              </div>
            )}
            <div className={styles.submit}>
              {errors.error && (
                <p className={styles.error}>{errors.error.message}</p>
              )}
              <div>
                <Button
                  disabled={isSubmitting || !selectedReason}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit Request
                </Button>
              </div>
            </div>
          </form>
        </>
      )}

      {isSubmitted && (
        <div className={styles.success}>
          <p>
            Request received. Someone from our team will reach out to discuss
            your NIL Valuation.
          </p>
          <Button onClick={closeModal} variant="contained">
            Close
          </Button>
        </div>
      )}
    </div>
  );
};
