import { ModalBase, ModalSize } from '@on3/ui-lib/components/Modal';
import {
  DashboardModalPropsMap,
  dashboardModals,
  DashboardModalType,
} from 'components/Modals/Dashboard';
import {
  PianoModalPropsMap,
  pianoModals,
  PianoModalType,
} from 'components/Modals/Piano';
import {
  PlayerModalPropsMap,
  playerModals,
  PlayerModalType,
} from 'components/Modals/Player';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type ModalType = PlayerModalType | DashboardModalType | PianoModalType;

export type ModalPropsMap = PlayerModalPropsMap &
  DashboardModalPropsMap &
  PianoModalPropsMap;

export interface IModal<T extends ModalType = ModalType> {
  type: T;
  props: ModalPropsMap[T];
  size: ModalSize;
  required: boolean;
}

interface IModalContext {
  showModal: (
    type: ModalType,
    props?: ModalPropsMap[ModalType],
    size?: ModalSize,
    required?: boolean,
  ) => void;
  closeModal: () => void;
  setModal: Dispatch<SetStateAction<IModal | null>>;
}

const ModalContext = createContext<IModalContext>({
  showModal: () => {},
  closeModal: () => {},
  setModal: () => {},
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modal, setModal] = useState<IModal | null>(null);

  const modals = useMemo(
    () => ({
      ...playerModals,
      ...dashboardModals,
      ...pianoModals,
    }),
    [],
  );

  // Show modal
  const showModal = useCallback(
    (
      type: ModalType,
      props: ModalPropsMap[ModalType] = {},
      size: ModalSize = 'default',
      required: boolean = false,
    ) => {
      setModal({ type, props, size, required });
    },
    [setModal],
  );

  // Close modal
  const closeModal = useCallback(() => setModal(null), [setModal]);

  const ModalComponent = modal ? modals[modal.type] : null;

  return (
    <ModalContext.Provider
      value={{
        showModal,
        closeModal,
        setModal,
      }}
    >
      {children}
      {modal && ModalComponent && (
        <ModalBase
          close={closeModal}
          required={modal.required}
          show={!!modal}
          size={modal.size}
        >
          <ModalComponent {...(modal.props as any)} />
        </ModalBase>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
};

export const withModal = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  const WrappedComponent = (props: P) => {
    return (
      <ModalProvider>
        <Component {...props} />
      </ModalProvider>
    );
  };

  return WrappedComponent;
};
