import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface AppleLoginRequest {
  /** @minLength 1 */
  idToken: string;
  /** If `true` then a new user is created if the email is not found */
  registerIfNew: boolean;
}

export interface BadRequestResult {
  /** @format int32 */
  statusCode: number;
}

export interface CreateDeviceTokenRequest {
  /** @format int32 */
  buildNumber?: number | null;
  client: DeviceClient;
  deviceType: DeviceType;
  /** @minLength 1 */
  token: string;
}

export enum DeviceClient {
  FanApp = 'FanApp',
  EliteApp = 'EliteApp',
}

export interface DeviceTokenResponse {
  /** @format int32 */
  buildNumber?: number | null;
  client?: DeviceClient;
  deviceType: DeviceType;
  /** @format int64 */
  key: number;
  /** @format date-time */
  lastSeenAt?: string | null;
  token?: string;
  /** @format int64 */
  userKey: number;
}

export enum DeviceType {
  Android = 'Android',
  Browser = 'Browser',
  IOS = 'iOS',
}

export enum ExternalStore {
  Apple = 'Apple',
  Google = 'Google',
  Amazon = 'Amazon',
}

export interface GoogleLoginRequest {
  /** @minLength 1 */
  accessToken: string;
  /** If `true` then a new user is created if the email is not found */
  registerIfNew: boolean;
}

/** Request representing a user login attempt */
export interface LoginRequest {
  /**
   * The email address of the user
   * @minLength 1
   */
  email: string;
  /**
   * The user's password
   * @minLength 1
   */
  password: string;
}

export interface MobileReceiptRequest {
  /**
   * The Product ID in the external store for the purchased subscription.
   * @minLength 1
   */
  productId: string;
  /** @minLength 1 */
  receipt: string;
  store: ExternalStore;
}

export interface PasswordResetRequest {
  /** @minLength 1 */
  email: string;
}

export interface PianoCodeLoginRequest {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  redirectUri: string;
}

export interface PianoDefaultResponse {
  /** @format int32 */
  code: number;
  message?: string | null;
  /** @format int32 */
  ts: number;
  validation_errors?: Record<string, string>;
}

export interface ProblemDetails {
  detail?: string | null;
  instance?: string | null;
  /** @format int32 */
  status?: number | null;
  title?: string | null;
  type?: string | null;
  [key: string]: any;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

/**
 * Response representing a user and the JWT
 * associated
 */
export interface UserJwtResponse {
  /** The display name of the user */
  displayName?: string | null;
  email: string | null;
  /** Does the user have at least one active subscription */
  hasActiveSubscription: boolean;
  latestTransactionId?: string;
  /** @format int64 */
  personKey?: number | null;
  personKeys?: number[];
  /** The pianoId of the user */
  pianoId?: string | null;
  promotionEligible: boolean;
  /**
   * The time when the Token will be expired
   * @format date-time
   */
  refreshBefore: string;
  /** The refresh token used to get a new Token */
  refreshToken?: string;
  /**
   * The date/time the refresh token expires
   * @format date-time
   */
  refreshTokenExpiry: string;
  /** The list of string roles */
  roles?: string[];
  sites?: number[];
  /** What is the max type of subscription a user has */
  subscriptionMaxType?: string;
  /** List of active subscription keys */
  subscriptions?: number[];
  /** The JWT to use */
  token?: string;
  /**
   * The id of the user
   * @format int64
   */
  userId: number;
  /** The username of the user */
  username?: string;
}

export type V2UsersDeleteUserAccountDeletePayload = string;

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags User
 * @name V2UsersDeleteUserAccountDelete
 * @summary Performs a GDPR-compliant deletion of the user's account
 * @request DELETE:/users/v2/users/delete-user-account
 * @secure
 */
export const v2UsersDeleteUserAccountDelete = (
  client: AxiosInstance,
  data: V2UsersDeleteUserAccountDeletePayload,
  params: RequestParams = {},
) =>
  client
    .request<PianoDefaultResponse>({
      url: `/users/v2/users/delete-user-account`,
      method: 'DELETE',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V2UsersDeviceTokensCreate
 * @summary  (Roles: registered user)
 * @request POST:/users/v2/users/{userKey}/device-tokens
 * @secure
 */
export const v2UsersDeviceTokensCreate = (
  client: AxiosInstance,
  userKey: number,
  data: CreateDeviceTokenRequest,
  params: RequestParams = {},
) =>
  client
    .request<DeviceTokenResponse>({
      url: `/users/v2/users/${userKey}/device-tokens`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V2UsersDeviceTokensStaleDelete
 * @summary  (Roles: developer)
 * @request DELETE:/users/v2/users/device-tokens/stale
 * @secure
 */
export const v2UsersDeviceTokensStaleDelete = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v2/users/device-tokens/stale`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V2UsersLoginCodeCreate
 * @request POST:/users/v2/users/login/code
 */
export const v2UsersLoginCodeCreate = (
  client: AxiosInstance,
  data: PianoCodeLoginRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/users/v2/users/login/code`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V2UsersLoginCreate
 * @summary Login through Piano
 * @request POST:/users/v2/users/login
 */
export const v2UsersLoginCreate = (
  client: AxiosInstance,
  data: LoginRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/users/v2/users/login`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V2UsersLoginSocialAppleCreate
 * @request POST:/users/v2/users/login/social/apple
 */
export const v2UsersLoginSocialAppleCreate = (
  client: AxiosInstance,
  data: AppleLoginRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/users/v2/users/login/social/apple`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V2UsersLoginSocialGoogleCreate
 * @request POST:/users/v2/users/login/social/google
 */
export const v2UsersLoginSocialGoogleCreate = (
  client: AxiosInstance,
  data: GoogleLoginRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/users/v2/users/login/social/google`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V2UsersReceiptCreate
 * @summary Submit and process receipts originating from StoreKit2 purchases.
 * @request POST:/users/v2/users/{userKey}/receipt
 * @secure
 */
export const v2UsersReceiptCreate = (
  client: AxiosInstance,
  userKey: number,
  data: MobileReceiptRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/users/v2/users/${userKey}/receipt`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V2UsersSendPasswordResetCreate
 * @summary Send a password reset request to Piano
 * @request POST:/users/v2/users/send-password-reset
 */
export const v2UsersSendPasswordResetCreate = (
  client: AxiosInstance,
  data: PasswordResetRequest,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v2/users/send-password-reset`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);
