/**
 * Slugify function that should be used going forward. Exits early if undefined string. Prevents checks where used.
 *
 * @param {string} str
 *
 * @returns string
 */
export const slugify = (str?: string | null) => {
  if (!str) return '';

  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};
