export const updatePath = <T extends object>(
  obj: T,
  path: string,
  value: any,
): T => {
  const keys = path.split('.');

  // Shallow merge if there is only one key
  if (keys.length === 1) {
    return { ...obj, [keys[0]]: value };
  }

  const [key, ...restKeys] = keys;

  // Ensure the parent object exists and recursively update
  return Array.isArray(obj)
    ? ([...obj].map((item, i) =>
        i === Number(key) ? updatePath(item, restKeys.join('.'), value) : item,
      ) as T)
    : {
        ...obj,
        [key]: updatePath((obj as any)[key] ?? {}, restKeys.join('.'), value),
      };
};
