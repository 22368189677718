import { Close } from '@mui/icons-material';
import { PersonAutoComplete } from '@on3/ui-lib/components/AutoComplete/PersonAutoComplete';
import { Avatar } from '@on3/ui-lib/components/Avatar';
import { Checkbox } from '@on3/ui-lib/components/Checkbox/Checkbox';
import { Form } from '@on3/ui-lib/components/Form';
import { Input } from '@on3/ui-lib/components/Input/Input';
import { generateKey } from '@on3/ui-lib/utils/generateKey';
import { updatePath } from '@on3/ui-lib/utils/updatePath';
import { yup } from '@on3/ui-lib/utils/yup';
import { TopInterestVisit } from 'components/Elite/Profile/Edits/TopInterests';
import { useState } from 'react';

import styles from './VisitsModal.module.scss';

interface VisitsModalProps {
  organization: {
    key: number;
    label: string;
    assetUrl?: string;
  };
  onSave: (values: any) => Promise<void> | void;
  visits?: TopInterestVisit[];
}

const schema = yup.array().of(
  yup.object({
    key: yup
      .mixed()
      .transform((v) => Number(v) || null)
      .nullable(),
    dateOccurred: yup.string().required().label('Date'),
    type: yup.string().oneOf(['visit', 'coaching']).required().label('Type'),
    official: yup.boolean().when('type', {
      is: 'visit',
      then: yup.boolean().required(),
    }),
    coaches: yup
      .array()
      .of(
        yup.object({
          key: yup.number().required(),
          label: yup.string().required(),
        }),
      )
      .nullable()
      .when('type', {
        is: 'coaching',
        then: yup.array().min(1, 'At least one coach is required').required(),
      }),
  }),
);

export const VisitsModal = ({
  organization,
  visits = [],
  onSave,
}: VisitsModalProps) => {
  const initialVisits = visits.map((visit) => ({
    key: visit.key,
    type: visit.type,
    official: visit.official,
    dateOccurred: visit.dateOccurred,
    coaches: visit.coaches?.map((c) => ({ key: c.key, label: c.label })),
  })) as TopInterestVisit[];

  const [values, setValues] = useState<TopInterestVisit[]>(initialVisits);

  const handleAddVisit = (type: 'visit' | 'coaching') =>
    setValues((prev) => [
      {
        key: generateKey(),
        type,
        dateOccurred: new Date().toISOString(),
        official: false,
      },
      ...prev,
    ]);

  const handleRemoveVisit = (index: number) =>
    setValues((prev) => prev.filter((_, i) => i !== index));

  const handleUpdateVisit = (
    index: number,
    key: keyof TopInterestVisit,
    value: any,
  ) => setValues((prev) => updatePath(prev, `${index}.${key}`, value));

  return (
    <Form
      className={styles.block}
      onSubmit={onSave}
      schema={schema}
      values={values}
    >
      {({ processing, errors }) => (
        <>
          <header>
            <h2>Visits</h2>
            <div className={styles.school}>
              <figure>
                <Avatar
                  altText={organization.label}
                  height={24}
                  source={organization.assetUrl}
                  teamAvatar
                  width={24}
                />
              </figure>
              <span>{organization.label}</span>
            </div>
          </header>
          <div className={styles.actions}>
            <button
              className={styles.addBtn}
              disabled={processing}
              name="add-visit"
              onClick={() => handleAddVisit('visit')}
              type="button"
            >
              Add Visit
            </button>
            <button
              className={styles.addCoachBtn}
              disabled={processing}
              name="add-coach-visit"
              onClick={() => handleAddVisit('coaching')}
              type="button"
            >
              Add Coach Visit
            </button>
          </div>
          <ol className={styles.visits}>
            {values.map((visit, i) => (
              <li className={styles.visit} key={visit.key}>
                <div className={styles.date}>
                  <Input
                    defaultValue={visit.dateOccurred?.split('T')[0] ?? ''}
                    error={errors[`[${i}].dateOccurred`]}
                    label="Date"
                    onChange={(e) =>
                      handleUpdateVisit(
                        i,
                        'dateOccurred',
                        e.target.value
                          ? new Date(e.target.value).toISOString().split('T')[0]
                          : '',
                      )
                    }
                    type="date"
                  />
                </div>
                <div className={styles.type}>
                  {visit.type === 'visit' ? (
                    <Checkbox
                      error={errors[`[${i}].official`]}
                      id={`visit-${visit.dateOccurred}-${i}`}
                      label="Official"
                      onChange={(val) => handleUpdateVisit(i, 'official', val)}
                      value={visit.official}
                    />
                  ) : (
                    <PersonAutoComplete
                      error={errors[`[${i}].coaches`]}
                      id={`visits-${i}-coaches`}
                      label="Recruited By"
                      limit={2}
                      name={`visits-${i}-coaches`}
                      onChange={(val) => handleUpdateVisit(i, 'coaches', val)}
                      value={(visit.coaches ?? [])?.filter((c) => c.key)}
                    />
                  )}
                </div>
                <div className={styles.remove}>
                  <button
                    className={styles.removeBtn}
                    name={`remove-visit-${i}`}
                    onClick={() => handleRemoveVisit(i)}
                    type="button"
                  >
                    <span className="sr-only">Remove</span>
                    <Close />
                  </button>
                </div>
              </li>
            ))}
          </ol>
          <button
            className={styles.saveBtn}
            disabled={processing}
            type="submit"
          >
            Save
          </button>
        </>
      )}
    </Form>
  );
};
