import { SVGProps } from 'react';

interface IOn3RecruitProps extends SVGProps<SVGSVGElement> {
  variant?: 'white' | 'black';
}

export const On3Recruit = ({
  variant = 'white',
  fill,
  ...props
}: IOn3RecruitProps) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 181 24"
    width="181"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8948 16.0235C16.6127 16.9411 16.1895 17.7176 15.5547 18.3529C14.9199 18.9882 14.2145 19.4823 13.2271 19.8352C12.3102 20.1882 11.1816 20.3999 9.91203 20.3999H7.65498C6.38539 20.3999 5.25686 20.1882 4.2694 19.8352C3.35247 19.4823 2.57661 18.9882 1.94181 18.3529C1.30702 17.7176 0.954352 16.9411 0.672221 16.0235C0.390089 15.1058 0.249023 14.1176 0.249023 12.9882V10.3058C0.249023 9.1764 0.390089 8.18816 0.672221 7.27051C0.954352 6.35287 1.37755 5.5764 2.01235 4.9411C2.57661 4.23522 3.35247 3.7411 4.33993 3.38816C5.25686 3.03522 6.38539 2.89404 7.72551 2.89404H9.98256C11.2522 2.89404 12.3807 3.03522 13.2976 3.38816C14.2145 3.7411 14.9904 4.23522 15.6252 4.87051C16.26 5.50581 16.6832 6.28228 16.9653 7.19992C17.2475 8.11757 17.3885 9.10581 17.3885 10.2352V12.9176C17.3885 14.1176 17.2475 15.1058 16.8948 16.0235ZM4.0578 16.9411C4.6926 17.7176 5.82112 18.0705 7.37285 18.0705H10.3352C11.887 18.0705 13.0155 17.7176 13.6503 16.9411C14.2851 16.1646 14.6377 15.0352 14.6377 13.5529V9.59993C14.6377 8.04698 14.2851 6.91757 13.6503 6.21169C13.0155 5.43522 11.887 5.08228 10.3352 5.08228H7.37285C5.82112 5.08228 4.76313 5.43522 4.0578 6.21169C3.423 6.98816 3.07034 8.11757 3.07034 9.67051V13.6235C3.07034 15.1058 3.423 16.2352 4.0578 16.9411Z"
      fill={fill ?? '#fe3b1f'}
    />
    <path
      d="M32.3414 20.6822C31.6361 20.6822 31.0013 20.4705 30.5781 20.1175C30.1549 19.7646 29.7317 19.2705 29.379 18.7058L23.5953 9.38811C23.4543 9.17634 23.3837 9.03517 23.3132 8.96458C23.3132 8.89399 23.2427 8.89399 23.1721 8.89399C23.0311 8.89399 23.0311 8.96458 23.0311 8.96458C22.9605 9.03517 22.9605 9.17634 22.9605 9.31752V20.5411H20.3508V9.31752C20.3508 8.47046 20.5624 7.76458 20.9856 7.19987C21.4088 6.56458 22.1142 6.28223 23.1016 6.28223C23.8069 6.28223 24.3712 6.49399 24.7944 6.91752C25.2176 7.27046 25.6408 7.83517 26.064 8.54105L31.7772 17.7175C31.8477 17.8587 31.9888 17.9999 32.0593 18.0705C32.1298 18.1411 32.1298 18.1411 32.2004 18.1411C32.2709 18.1411 32.2709 18.1411 32.3414 18.0705C32.412 17.9999 32.412 17.9293 32.412 17.7881V6.49399H35.0217V17.7175C35.0217 18.5646 34.8101 19.2705 34.3869 19.7646C33.8226 20.3999 33.1878 20.6822 32.3414 20.6822Z"
      fill={fill ?? '#fe3b1f'}
    />
    <path
      clipRule="evenodd"
      d="M42.851 24H50.6096C53.2193 24 55.2647 21.9529 55.2647 19.4118V4.65882C55.2647 2.11765 53.2193 0 50.6096 0H42.851C40.3118 0 38.1958 2.11765 38.1958 4.65882V19.3412C38.1958 21.8824 40.3118 24 42.851 24ZM47.5061 20.6823C49.6221 20.5411 51.3148 19.0587 51.738 17.0823C51.8086 16.7999 51.8086 16.447 51.8086 16.1646C51.8086 14.6823 51.0327 13.2705 49.7631 12.4234L49.3399 12.0705L49.7631 11.7176C51.0327 10.8705 51.8086 9.45874 51.8086 7.97639C51.8086 7.62345 51.8086 7.34109 51.738 7.05874C51.3148 5.08227 49.5515 3.59992 47.5061 3.45874H41.5813V6.84698H47.4355C47.9998 6.91756 48.423 7.34109 48.4935 7.83521V8.11756C48.4935 8.98097 47.3536 9.71401 46.3131 10.3831C45.8224 10.6986 45.3539 10.9999 45.0374 11.294V12.7764C45.3586 13.0549 45.8032 13.3335 46.2668 13.6239C47.3304 14.2902 48.4935 15.0188 48.4935 15.9529V16.2352C48.423 16.7293 47.9998 17.1529 47.4355 17.2234H41.6518V20.6823H47.5061Z"
      fill={fill ?? '#fe3b1f'}
      fillRule="evenodd"
    />
    <path
      d="M176.262 13.2101L172.462 12.1701C171.142 11.8101 170.142 11.3301 169.422 10.7301C168.702 10.1301 168.362 9.27012 168.362 8.15012C168.362 7.03012 168.782 6.13012 169.622 5.43012C170.462 4.73012 171.762 4.37012 173.502 4.37012H180.502V6.37012H173.242C172.282 6.37012 171.602 6.55012 171.242 6.87012C170.882 7.21012 170.702 7.61012 170.702 8.05012C170.702 8.57012 170.862 8.99012 171.202 9.27012C171.542 9.57012 172.162 9.85012 173.082 10.0901L176.882 11.1701C178.202 11.5501 179.202 12.0501 179.922 12.6701C180.642 13.2901 181.002 14.1501 181.002 15.2501C181.002 16.4901 180.582 17.4901 179.742 18.2501C178.902 19.0101 177.642 19.3701 175.942 19.3701H168.762V17.3701H176.162C177.042 17.3701 177.682 17.2101 178.082 16.8501C178.482 16.5101 178.702 16.0501 178.702 15.4501C178.702 14.8501 178.522 14.3901 178.182 14.0701C177.842 13.7501 177.202 13.4701 176.262 13.2101Z"
      fill={variant === 'black' ? '#3B4147' : 'white'}
    />
    <path
      d="M158.188 19.3701V6.37012H152.188V4.37012H166.488V6.37012H160.488V19.3701H158.188Z"
      fill={variant === 'black' ? '#3B4147' : 'white'}
    />
    <path
      d="M146.713 19.3701V4.37012H149.013V19.3701H146.713Z"
      fill={variant === 'black' ? '#3B4147' : 'white'}
    />
    <path
      d="M133.649 19.6301C131.689 19.6301 130.269 19.2501 129.389 18.4501C128.489 17.6701 128.049 16.4301 128.049 14.7101V4.37012H130.349V14.8701C130.349 15.8101 130.569 16.5101 131.049 16.9501C131.529 17.4101 132.269 17.6301 133.269 17.6301H136.869C137.809 17.6301 138.529 17.4101 139.009 16.9501C139.489 16.5101 139.749 15.8101 139.749 14.8701V4.37012H142.049V14.7101C142.049 16.4301 141.589 17.6701 140.689 18.4501C139.769 19.2501 138.289 19.6301 136.249 19.6301H133.649Z"
      fill={variant === 'black' ? '#3B4147' : 'white'}
    />
    <path
      d="M113.887 19.3701H111.587V11.8901C111.587 11.5301 111.707 11.2501 111.967 11.0301C112.207 10.8301 112.507 10.7101 112.847 10.7101H119.707C120.567 10.7101 121.167 10.5901 121.507 10.3101C121.847 10.0501 122.027 9.55012 122.027 8.83012V8.21012C122.027 7.49012 121.847 7.01012 121.507 6.75012C121.167 6.51012 120.567 6.37012 119.707 6.37012H107.527V4.37012H119.207C119.927 4.37012 120.607 4.43012 121.247 4.55012C121.887 4.67012 122.427 4.89012 122.907 5.17012C123.367 5.47012 123.727 5.87012 123.987 6.37012C124.247 6.87012 124.387 7.49012 124.387 8.25012V8.79012C124.387 10.3101 123.907 11.3501 122.987 11.8701C122.067 12.4101 120.767 12.6701 119.107 12.6701H117.867L124.367 19.3701H121.347L114.807 12.6701H113.887V19.3701Z"
      fill={variant === 'black' ? '#3B4147' : 'white'}
    />
    <path
      d="M108.464 17.3701V19.3701H101.844C100.664 19.3701 99.664 19.2301 98.824 18.9101C97.984 18.6101 97.284 18.1701 96.764 17.5901C96.244 17.0301 95.844 16.3301 95.604 15.5301C95.364 14.7301 95.244 13.8101 95.244 12.8101V10.9301C95.244 9.93012 95.364 9.03012 95.604 8.23012C95.844 7.43012 96.244 6.73012 96.764 6.15012C97.284 5.59012 97.984 5.15012 98.824 4.83012C99.664 4.53012 100.664 4.37012 101.844 4.37012H108.364V6.37012H101.544C100.144 6.37012 99.164 6.71012 98.604 7.37012C98.024 8.03012 97.744 9.03012 97.744 10.3701V13.3701C97.744 14.7301 98.024 15.7301 98.604 16.3901C99.164 17.0501 100.144 17.3701 101.544 17.3701H108.464Z"
      fill={variant === 'black' ? '#3B4147' : 'white'}
    />
    <path
      d="M79.9214 19.3701V4.37012H91.941V6.37012H82.2214V10.7501H91.941V12.7501H82.2214V17.3701H91.941V19.3701H79.9214Z"
      fill={variant === 'black' ? '#3B4147' : 'white'}
    />
    <path
      d="M76 19.3701H73.2L67.28 12.9501C67.08 12.7501 66.94 12.5101 66.86 12.2301C66.78 11.9701 66.78 11.7301 66.86 11.4901C66.92 11.2901 67.06 11.1101 67.28 10.9501C67.5 10.7901 67.78 10.7101 68.12 10.7101H71.58C72.44 10.7101 73.04 10.5901 73.38 10.3101C73.72 10.0501 73.9 9.55012 73.9 8.83012V8.21012C73.9 7.49012 73.72 7.01012 73.38 6.75012C73.04 6.51012 72.44 6.37012 71.58 6.37012H65.3V19.3701H63V4.37012H71.08C71.8 4.37012 72.48 4.43012 73.12 4.55012C73.76 4.67012 74.3 4.89012 74.78 5.17012C75.24 5.47012 75.6 5.87012 75.86 6.37012C76.12 6.87012 76.26 7.49012 76.26 8.25012V8.79012C76.26 10.3101 75.78 11.3501 74.86 11.8701C73.94 12.4101 72.64 12.6701 70.98 12.6701H70L76 19.3701Z"
      fill={variant === 'black' ? '#3B4147' : 'white'}
    />
  </svg>
);
