import { Upload } from '@mui/icons-material';
import {
  EditType,
  v1PeopleProfileEditsCreate,
  v1ProfileEditsAttachFileUpdate,
} from '@on3/api';
import { Avatar } from '@on3/ui-lib/components/Avatar';
import { Form } from '@on3/ui-lib/components/Form';
import { externalApi, useToast } from '@on3/ui-lib/index';
import { yup } from '@on3/ui-lib/utils/yup';
import clsx from 'clsx';
import { usePlayerProfile } from 'contexts/PlayerProfile/PlayerProvider';
import { useState } from 'react';

import styles from './Forms.module.scss';

const schema = yup.object().shape({
  file: yup.mixed().required(),
});

export const PhotoForm = ({ onComplete }: { onComplete: () => void }) => {
  const {
    state: { player },
  } = usePlayerProfile();
  const { notify } = useToast();

  const asset = player?.defaultAsset;

  const [file, setFile] = useState<File>();

  const handleSubmit = async (values: { file: File }) => {
    try {
      const { reviewItem } = await v1PeopleProfileEditsCreate(
        externalApi,
        player.key,
        {
          edit: {
            $type: 'profileImage',
            editType: EditType.Add,
          },
        },
      );

      await v1ProfileEditsAttachFileUpdate(
        externalApi,
        reviewItem.key,
        {
          file: values.file,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      onComplete();
    } catch (error) {
      notify('Failed to update profile photo.', {
        type: 'error',
      });
    }
  };

  return (
    <Form
      className={clsx(styles.form, styles.profilePhoto)}
      onSubmit={handleSubmit}
      schema={schema}
      values={{ file }}
    >
      {({ processing, errors }) => (
        <>
          <div className={styles.interactive}>
            <figure className={styles.avatar}>
              {file ? (
                <img
                  alt={file.name}
                  src={URL.createObjectURL(file)}
                  title={file.name}
                />
              ) : (
                <Avatar
                  altText={asset?.altText}
                  domain={asset?.domain}
                  height={150}
                  source={asset?.source}
                  width={150}
                />
              )}
            </figure>
            <div className={styles.upload}>
              <label>
                <input
                  hidden
                  name="file-upload"
                  onChange={(e) => setFile(e.target.files?.[0])}
                  type="file"
                />
                <div role="button" tabIndex={0}>
                  <Upload />
                  <span>Upload</span>
                </div>
              </label>
              <p>For best results, use an image at least 150px by 150px.</p>
            </div>
          </div>
          {errors.file && <p className={styles.error}>{errors.file}</p>}
          <footer className={styles.footer}>
            <button
              className={styles.skip}
              name="skip"
              onClick={() => onComplete()}
              type="button"
            >
              Skip
            </button>
            <button disabled={processing || !file} name="save" type="submit">
              Continue
            </button>
          </footer>
        </>
      )}
    </Form>
  );
};
