import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar } from '@mui/material';
import {
  type PersonContactInfoResponse,
  type PlayerProfileResponse,
  type PlayerResponse,
  v1PeopleContactInfoUpdate,
} from '@on3/api';
import {
  On3IconInstagram,
  On3IconTikTok,
  On3IconTwitter,
} from '@on3/icons/src';
import { Input } from '@on3/ui-lib/src/components/Input/Input';
import { filterPhoneNumber } from '@on3/ui-lib/src/utils/filters';
import { formatPhoneNumber } from '@on3/ui-lib/src/utils/formatters';
import { externalApi } from '@on3/ui-lib/utils/api';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import styles from './ContactModal.module.scss';

interface IContactInfoProps {
  player: PlayerResponse | PlayerProfileResponse | any;
  contactInfo: PersonContactInfoResponse;
  callback?: () => void;
}

const phoneSchema = yup
  .string()
  .transform((value) => filterPhoneNumber(value))
  .matches(/^\d{10}$/, {
    message: 'Please enter a valid phone number',
    excludeEmptyString: true,
  });

const emailSchema = yup.string().email('Please enter a valid email address');

const contactSchema = yup.object().shape({
  value: yup.object().shape({
    name: yup.string().nullable(),
    email: emailSchema,
    phoneNumber: phoneSchema,
  }),
});

export const schema = yup.object().shape({
  phoneNumber: yup.object().shape({
    value: phoneSchema,
  }),
  email: yup.object().shape({
    value: emailSchema,
  }),
  agentInfo: contactSchema,
  parentInfo: contactSchema,
  secondaryParentInfo: contactSchema,
});

export const ContactModal = ({
  player,
  contactInfo,
  callback = () => {},
}: IContactInfoProps) => {
  const playerName = player.fullName || player.name || 'Player';

  const {
    phoneNumber,
    email,
    instagramHandle,
    twitterHandle,
    tikTokHandle,
    agentInfo,
    parentInfo,
    secondaryParentInfo,
  } = contactInfo;

  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PersonContactInfoResponse>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      phoneNumber: {
        value: phoneNumber?.value || '',
      },
      email: {
        value: email?.value ?? '',
      },
      instagramHandle: { value: instagramHandle?.value ?? '' },
      twitterHandle: { value: twitterHandle?.value ?? '' },
      tikTokHandle: { value: tikTokHandle?.value ?? '' },
      agentInfo: {
        value: {
          agencyName: agentInfo?.value?.agencyName ?? '',
          name: agentInfo?.value?.name ?? '',
          email: agentInfo?.value?.email ?? '',
          phoneNumber: agentInfo?.value?.phoneNumber || '',
        },
      },
      parentInfo: {
        value: {
          name: parentInfo?.value?.name ?? '',
          email: parentInfo?.value?.email ?? '',
          phoneNumber: parentInfo?.value?.phoneNumber || '',
        },
      },
      secondaryParentInfo: {
        value: {
          name: secondaryParentInfo?.value?.name ?? '',
          email: secondaryParentInfo?.value?.email ?? '',
          phoneNumber: secondaryParentInfo?.value?.phoneNumber || '',
        },
      },
    },
  });

  const imageUrl = `https://${player?.defaultAsset?.domain}${player?.defaultAsset?.source}`;

  const onSubmit: SubmitHandler<PersonContactInfoResponse> = (data) => {
    setIsLoading(true);
    const personKey = player.key;

    v1PeopleContactInfoUpdate(externalApi, personKey, data)
      .then((r) => {
        router.refresh();
        callback();
      })
      .catch((error) => {});
  };

  return (
    <div className={styles.block}>
      <h2>Edit Contact Information</h2>
      <div className={styles.meta}>
        <Avatar
          className={styles.avatar}
          src={imageUrl ?? ''}
          variant="square"
        />
        <div className={styles.vitals}>
          <p className={styles.name}>{playerName}</p>
        </div>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <Controller
              control={control}
              name="phoneNumber.value"
              render={({ field: { value, onChange, ...field } }) => (
                <Input
                  error={errors.phoneNumber?.value}
                  id="phoneNumber"
                  label="Phone Number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(filterPhoneNumber(e.target.value));
                  }}
                  type="tel"
                  value={formatPhoneNumber(value || '')}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="email.value"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.email?.value}
                  id="email"
                  label="Email Address"
                  type="email"
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="instagramHandle.value"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.instagramHandle?.value}
                  id="instagramHandle"
                  label="Instagram"
                  prefix={<On3IconInstagram />}
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="twitterHandle.value"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.twitterHandle?.value}
                  id="twitterHandle"
                  label="Twitter"
                  prefix={<On3IconTwitter />}
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="tikTokHandle.value"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.tikTokHandle?.value}
                  id="tikTokHandle"
                  label="TikTok"
                  prefix={<On3IconTikTok />}
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div>
          <h3>Agent/Representative</h3>
          <div>
            <Controller
              control={control}
              name="agentInfo.value.name"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.agentInfo?.value?.name}
                  id="agentName"
                  label="Name"
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="agentInfo.value.agencyName"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.agentInfo?.value?.name}
                  id="agencyName"
                  label="Agency"
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="agentInfo.value.phoneNumber"
              render={({ field: { value, onChange, ...field } }) => (
                <Input
                  error={errors.agentInfo?.value?.phoneNumber}
                  id="agentPhoneNumber"
                  label="Phone Number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(filterPhoneNumber(e.target.value));
                  }}
                  type="tel"
                  value={formatPhoneNumber(value || '')}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="agentInfo.value.email"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.agentInfo?.value?.email}
                  id="agentEmail"
                  label="Email Address"
                  type="email"
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div>
          <h3>Parent/Primary Guardian</h3>
          <div>
            <Controller
              control={control}
              name="parentInfo.value.name"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.parentInfo?.value?.name}
                  id="parentName"
                  label="Name"
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="parentInfo.value.phoneNumber"
              render={({ field: { value, onChange, ...field } }) => (
                <Input
                  error={errors.parentInfo?.value?.phoneNumber}
                  id="parentPhoneNumber"
                  label="Phone Number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(filterPhoneNumber(e.target.value));
                  }}
                  type="tel"
                  value={formatPhoneNumber(value || '')}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="parentInfo.value.email"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.parentInfo?.value?.email}
                  id="parentEmail"
                  label="Email Address"
                  type="email"
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div>
          <h3>Secondary Guardian</h3>
          <div>
            <Controller
              control={control}
              name="secondaryParentInfo.value.name"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.secondaryParentInfo?.value?.name}
                  id="secondaryParentName"
                  label="Name"
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="secondaryParentInfo.value.phoneNumber"
              render={({ field: { value, onChange, ...field } }) => (
                <Input
                  error={errors.secondaryParentInfo?.value?.phoneNumber}
                  id="secondaryParentPhoneNumber"
                  label="Phone Number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(filterPhoneNumber(e.target.value));
                  }}
                  type="tel"
                  value={formatPhoneNumber(value || '')}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="secondaryParentInfo.value.email"
              render={({ field: { value, ...field } }) => (
                <Input
                  error={errors.secondaryParentInfo?.value?.email}
                  id="secondaryParentEmail"
                  label="Email Address"
                  type="email"
                  value={value ?? ''}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.submit}>
          <button disabled={isLoading} type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};
