import { SportFilter, SportResponseRecord } from '@on3/api';
import { baseSports } from '@on3/ui-lib/store/mocks/sports';

import { slugify } from './slugify';

function getIdFromParam(slug: string): number | null {
  const id = slug.split('-').pop();

  return id ? +id : null;
}

function getSportKeyFromParam(sport: string): number | null {
  return (
    baseSports.find((s) => slugify(s?.name) === slugify(sport))?.key ||
    baseSports.find((s) => slugify(s.abbreviation) === slugify(sport))?.key ||
    null
  );
}

/**
  Use when we want to pull more than the base sports.
  Todo: remove references to base sports. Sports are fetched on each request
  https://db.on3.com/sports/ Managing sports
*/
function getRankingSportKey(
  sport: string,
  rankingSports: SportFilter[],
): number | null {
  return (
    rankingSports.find((s) => slugify(s?.name) === slugify(sport))?.key ||
    rankingSports.find((s) => slugify(s.abbreviation) === slugify(sport))
      ?.key ||
    null
  );
}

function getHighSchoolSportLabel(sport: SportFilter | SportResponseRecord) {
  switch (sport.key) {
    case 2:
      return 'Boys Basketball';
    case 5:
      return 'Girls Basketball';
    default:
      return sport.name as string;
  }
}

export {
  getHighSchoolSportLabel,
  getIdFromParam,
  getRankingSportKey,
  getSportKeyFromParam,
};
