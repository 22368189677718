import {
  type DraftSimpleResponse,
  OrganizationType,
  type PersonSportOrganizationResponse,
  PersonStatusTier,
  type PlayerOrgResponse,
  type PlayerProfileResponse,
  rdbV1PeopleImagesDetail,
  rdbV1PeopleLatestValuationDetail,
  rdbV1PeopleMeasurementsAveragesDetail,
  rdbV1PeopleMeasurementsDetail,
  rdbV1PeopleSocialDetail,
  rdbV1PeopleValuationGrowthDetail,
  rdbV1PersonPrimaryRecruitmentEvaluationDetail,
  rdbV1PersonRecruitmentEvaluationsDetail,
  rdbV1PersonVideosDetail,
  rdbV1PlayerContactInfoDetail,
  rdbV1PlayerEducationDetail,
  rdbV1PlayerOrganizationsDetail,
  rdbV1PlayerOrganizationsDetail2,
  rdbV1PlayerProfileDetail,
  rdbV1PlayerRankingsDetail,
  rdbV1PlayerTeamTargetsDetail,
  rdbV1PlayerVisitsDetail,
  rdbV1RecruitmentsProfileDetail,
  rdbV2PeoplePersonConnectionsDetail,
  rdbV2PlayerAllRankingsDetail,
  v1PeopleContactInfoDetail,
  v1PeopleHeadlinesPaginatedDetail,
  v1PeopleNilDashboardValuationBreakdownDetail,
  v1PeopleProfileEditsDetail,
  v1RecruitmentsRecruitmentInterestsAdminDetail,
  v1SettingsEliteProfileSettingsDetail,
  v2PeopleDealsDetail,
  v2PersonProfileAdminDetail,
  v2PersonSportsDetail,
} from '@on3/api';
import { getSportKeyFromParam } from '@on3/ui-lib/utils/url';

export enum AthleteType {
  Base = 'Base',
  Emerging = 'Emerging',
  Scouting = 'Scouting',
  Star = 'Star',
  Juco = 'Juco',
  Transfer = 'Transfer',
  Signee = 'Signee',
  College = 'College',
  Pro = 'Pro',
}

export interface IPlayerProfile {
  index: number;
  draft?: DraftSimpleResponse;
  organizations: PersonSportOrganizationResponse[];
  currentPlayerTeam?: PersonSportOrganizationResponse;
  prevTeamKey?: number;
  nextTeamKey?: number;
  strength?: number | null;
  currentIndex?: number;
}

export type Athlete = PlayerProfileResponse & {
  profile?: IPlayerProfile;
};

export const playerModules = {
  academics: rdbV1PlayerEducationDetail,
  college: rdbV1PlayerOrganizationsDetail,
  contacts: {
    public: rdbV1PlayerContactInfoDetail,
    private: v1PeopleContactInfoDetail,
  },
  news: v1PeopleHeadlinesPaginatedDetail,
  nil: {
    breakdown: v1PeopleNilDashboardValuationBreakdownDetail,
    deals: v2PeopleDealsDetail,
    growth: rdbV1PeopleValuationGrowthDetail,
    valuation: rdbV1PeopleLatestValuationDetail,
  },
  organizations: rdbV1PlayerOrganizationsDetail2,
  photos: rdbV1PeopleImagesDetail,
  player: rdbV1PlayerProfileDetail,
  rankings: {
    player: rdbV1PlayerRankingsDetail,
    all: rdbV2PlayerAllRankingsDetail,
  },
  recruit: rdbV1RecruitmentsProfileDetail,
  reminds: rdbV2PeoplePersonConnectionsDetail,
  scouting: {
    journals: rdbV1PersonRecruitmentEvaluationsDetail,
    summary: rdbV1PersonPrimaryRecruitmentEvaluationDetail,
  },
  social: rdbV1PeopleSocialDetail,
  topTeams: rdbV1PlayerTeamTargetsDetail,
  videos: rdbV1PersonVideosDetail,
  visits: rdbV1PlayerVisitsDetail,
  vitalAverages: rdbV1PeopleMeasurementsAveragesDetail,
  vitals: rdbV1PeopleMeasurementsDetail,
};

export const playerAdminModules = {
  details: v2PersonProfileAdminDetail,
  edits: v1PeopleProfileEditsDetail,
  settings: v1SettingsEliteProfileSettingsDetail,
  sports: v2PersonSportsDetail,
  topInterests: v1RecruitmentsRecruitmentInterestsAdminDetail,
};

// Determine which type of athlete the player is
const getAthleteType = (athlete: Athlete): AthleteType => {
  const inHighSchool =
    athlete.organizationLevel === OrganizationType.HighSchool ||
    athlete.organizationLevel === OrganizationType.PrepSchool;
  const inCollege =
    athlete.organizationLevel === OrganizationType.College ||
    athlete.organizationLevel === OrganizationType.Developmental;
  const inJuco = athlete.organizationLevel === OrganizationType.JuniorCollege;
  const inPro = athlete.organizationLevel === OrganizationType.Professional;
  const inTransferPortal =
    athlete.playerStatus?.type !== 'Enrolled' &&
    !athlete.playerStatus?.withdrawnTransfer &&
    !!athlete.playerStatus?.transfer;

  if (inTransferPortal) return AthleteType.Transfer;

  if (inJuco) return AthleteType.Juco;

  if (inCollege) return AthleteType.College;

  if (inPro) return AthleteType.Pro;

  if (inHighSchool && athlete.tier === PersonStatusTier.Star)
    return AthleteType.Star;

  return AthleteType.Base; // Default to star profile for now
};

// Get sport key from query param or player profile
// Default to football if not found
function getSportKeyFromPage(
  person: PlayerProfileResponse,
  param?: string,
): number {
  if (param && getSportKeyFromParam(param)) {
    return getSportKeyFromParam(param) as number;
  }

  if (person) {
    if (person.defaultSport?.key) {
      return person.defaultSport.key;
    }

    if (person.sports?.length) {
      return person.sports[0].key;
    }
  }

  return 1; // default to football
}

// Get the player profile
const getPlayerProfile = (playerOrganizations: PlayerOrgResponse | null) => {
  const organizations = playerOrganizations?.organizations ?? [];

  const profile: IPlayerProfile = {
    index: 0,
    organizations,
  };

  if (playerOrganizations?.draft) profile.draft = playerOrganizations.draft;
  if (organizations[0]) profile.currentPlayerTeam = organizations[0];
  if (organizations[1]?.organization?.key)
    profile.nextTeamKey = organizations[1]?.organization?.key;

  return profile;
};

export { getAthleteType, getPlayerProfile, getSportKeyFromPage };
