import { ComponentProps } from 'react';

import { ContactModal } from './Contact';
import { VisitsModal } from './Visits';

export type PlayerModalType = 'player.contact' | 'player.visits';

export interface PlayerModalPropsMap {
  'player.contact': ComponentProps<typeof ContactModal>;
  'player.visits': ComponentProps<typeof VisitsModal>;
}

export const playerModals = {
  'player.contact': ContactModal,
  'player.visits': VisitsModal,
};
