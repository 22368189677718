import { CloseOutlined } from '@mui/icons-material';
import { useClickOutside } from '@on3/ui-lib/hooks/useClickOutside';
import clsx from 'clsx';
import { useRef } from 'react';

import styles from './Modal.module.scss';

export type ModalSize = 'default' | 'sm' | 'md' | 'lg' | 'xl';

interface IModalBase {
  show: boolean;
  close: () => void;
  required?: boolean;
  size?: ModalSize;
  children: React.ReactNode;
}

export const ModalBase = ({
  show,
  close,
  required = false,
  size = 'default',
  children,
}: IModalBase) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, close, required);

  if (!show) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div
          className={clsx(styles.modal, {
            [styles.sm]: size === 'sm',
            [styles.md]: size === 'md',
            [styles.lg]: size === 'lg',
            [styles.xl]: size === 'xl',
          })}
          ref={ref}
        >
          {!required && (
            <button className={styles.close} onClick={close}>
              <span className="sr-only">close</span>
              <CloseOutlined />
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
