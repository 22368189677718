import { Upload } from '@mui/icons-material';
import {
  EditType,
  ImageResponse,
  ProfileImageProfileEdit,
  v1PeopleProfileEditsCreate,
  v1ProfileEditsAttachFileUpdate,
} from '@on3/api';
import { Avatar } from '@on3/ui-lib/components/Avatar';
import { Form } from '@on3/ui-lib/components/Form';
import { externalApi, useToast } from '@on3/ui-lib/index';
import { yup } from '@on3/ui-lib/utils/yup';
import { useModal } from 'components/Modals';
import { usePlayerProfile } from 'contexts/PlayerProfile/PlayerProvider';
import { useState } from 'react';

import styles from './UpdateAvatar.module.scss';

interface IUpdateAvatarModal {
  asset?: ImageResponse;
}

const schema = yup.object().shape({
  file: yup.mixed().required(),
});

export const UpdateAvatarModal = ({ asset }: IUpdateAvatarModal) => {
  const {
    state: { player },
    setState,
  } = usePlayerProfile();
  const { notify } = useToast();
  const { closeModal } = useModal();

  const [file, setFile] = useState<File>();

  const handleSubmit = async (values: { file: File }) => {
    try {
      const { reviewItem } = await v1PeopleProfileEditsCreate(
        externalApi,
        player.key,
        {
          edit: {
            $type: 'profileImage',
            editType: EditType.Add,
          },
        },
      );

      await v1ProfileEditsAttachFileUpdate(
        externalApi,
        reviewItem.key,
        {
          file: values.file,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ).then((res) => {
        const newPhoto = res.reviewItem.newValue as ProfileImageProfileEdit;

        setState((prev) => ({
          player: {
            ...prev.player,
            defaultAsset: {
              ...(prev.player.defaultAsset || ({} as ImageResponse)),
              domain: newPhoto.file?.domain,
              source: `${newPhoto.file?.path}/${newPhoto.file?.fileName}`,
            },
          },
        }));
      });

      notify('Profile photo update submitted successfully.', {
        type: 'success',
      });
    } catch (error) {
      notify('Failed to update profile photo.', {
        type: 'error',
      });
    } finally {
      closeModal();
    }
  };

  return (
    <div className={styles.modal}>
      <h2>Update Profile Photo</h2>
      <Form
        className={styles.form}
        onSubmit={handleSubmit}
        schema={schema}
        values={{ file }}
      >
        {({ processing, errors }) => (
          <>
            <div className={styles.interactive}>
              <figure className={styles.avatar}>
                {file ? (
                  <img
                    alt={file.name}
                    src={URL.createObjectURL(file)}
                    title={file.name}
                  />
                ) : (
                  <Avatar
                    altText={asset?.altText}
                    domain={asset?.domain}
                    height={150}
                    source={asset?.source}
                    width={150}
                  />
                )}
              </figure>
              <div className={styles.upload}>
                <label>
                  <input
                    hidden
                    name="file-upload"
                    onChange={(e) => setFile(e.target.files?.[0])}
                    type="file"
                  />
                  <div role="button" tabIndex={0}>
                    <Upload />
                    <span>Upload</span>
                  </div>
                </label>
                <p>For best results, use an image at least 150px by 150px.</p>
              </div>
            </div>
            {errors.file && <p className={styles.error}>{errors.file}</p>}
            <div className={styles.actions}>
              <button name="cancel" onClick={closeModal} type="button">
                Cancel
              </button>
              <button disabled={processing || !file} name="save" type="submit">
                Save Photo
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
