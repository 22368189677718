import type { SportFilter } from '@on3/api';

/*** For base sports, we will only show football and basketball. When we need additional sports, we will fetch from api. */
export const baseSports: SportFilter[] = [
  {
    key: 1,
    name: 'Football',
    abbreviation: 'fb',
    isRankable: true,
    isIndustryRankable: true,
  },
  {
    key: 2,
    name: 'Basketball',
    abbreviation: 'bk',
    isRankable: true,
    isIndustryRankable: true,
  },
  {
    key: 5,
    name: `Women's Basketball`,
    abbreviation: 'Wbk',
    isRankable: true,
    isIndustryRankable: true,
  },
];
