import { type UserJwtResponse } from '@on3/api';
import type { IUser } from '@on3/ui-lib/api/schema/custom-contracts';
import jwtDecode from 'jwt-decode';
import Router from 'next/router';
import {
  Context,
  createContext,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Cookies from 'universal-cookie';

import { initialUser } from '../store/mocks/user';
import { externalApi, internalApi, proxyApi } from '../utils/api';
import { editorRoles } from './editorRoles';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tp: any;
  }
}

export const userAccessHeader = (user?: IUser | null) => {
  if (!user) {
    return 'guest';
  }

  const adminAccessRoles = [
    'author',
    'editor',
    'administrator',
    'contributer',
    'developer',
    'rdb1',
    'rdb2',
    'rdb3',
    'rdb4',
    'rdb5',
  ];

  if (
    (Array.isArray(user?.rg) &&
      user?.rg.some((role) => adminAccessRoles.includes(role))) ||
    (!Array.isArray(user?.rg) &&
      user?.rg &&
      adminAccessRoles.includes(user?.rg))
  ) {
    return 'admin';
  } else if (user?.has) {
    return 'subscriber';
  }

  return 'guest';
};

interface IAuthContext {
  user?: IUser;
  login: (username: string, password: string) => Promise<void>;
  setUserToken: (value: UserJwtResponse) => void;
  logout: (redirectUrl?: string) => void;
  setUser: (value: SetStateAction<IUser>) => void;
  isAuthenticated: () => boolean;
  hasRdbAccess: () => boolean;
  isVerified: (playerKey?: number | null) => boolean;
  isVerifiedPlayer: (playerKey?: string | number) => boolean;
  hasRdbLevel5Access: () => boolean;
  hasRdbLevel4Access: () => boolean;
  hasRdbLevel3Access: () => boolean;
  hasRdbLevel2Access: () => boolean;
  hasAccountingAdmin: () => boolean;
  hasPublisherAdmin: () => boolean;
  hasShowsAdmin: () => boolean;
  hasArticleEditAccess: () => boolean;
  canEditArticle: (siteKey: number) => boolean;
  isDeveloper: () => boolean;
  isTester: () => boolean;
  refreshToken: (oldRefreshToken?: string) => Promise<void>;
}

interface IAuthResponse {
  userData?: IUser;
  children: React.ReactNode;
}

const AuthContext: Context<IAuthContext> = createContext({} as IAuthContext);
const cookies = new Cookies();

export const AuthProvider = ({ children, userData }: IAuthResponse) => {
  const [user, setUser] = useState<IUser>(userData || initialUser);

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = cookies.get('token');
      const refresh = cookies.get('refreshToken');

      if (token) {
        if (expiredToken(token) && refresh) {
          refreshToken(refresh);
        } else {
          internalApi.defaults.headers.Authorization = `Bearer ${token}`;
          proxyApi.defaults.headers.Authorization = `Bearer ${token}`;
          externalApi.defaults.headers.Authorization = `Bearer ${token}`;
          const userObj: IUser = jwtDecode(token);

          setUser(userObj);
        }
      }
    }

    if (Router.asPath !== '/logout/') {
      loadUserFromCookies();
    }
  }, []);

  const login = useCallback(
    async (username: string, password: string): Promise<void> => {
      try {
        const { data } = await externalApi.post('/users/v1/users/login/', {
          username,
          password,
        });

        setUserToken(data);

        return new Promise((resolve) => {
          resolve();
        });
      } catch (error) {
        return new Promise((resolve, reject) => reject(error));
      }
    },
    [],
  );

  const setUserToken = ({
    token,
    refreshBefore,
    refreshToken,
  }: UserJwtResponse) => {
    const tokenExpires = new Date(refreshBefore || 0);
    const week = new Date();

    week.setDate(week.getDate() + 7);

    if (token) {
      // Sets JWT
      cookies.set('token', token, { expires: tokenExpires, path: '/' });
      cookies.set('refreshToken', refreshToken, { expires: week, path: '/' });
      internalApi.defaults.headers.Authorization = `Bearer ${token}`;
      externalApi.defaults.headers.Authorization = `Bearer ${token}`;
      proxyApi.defaults.headers.Authorization = `Bearer ${token}`;

      const userObj = jwtDecode<IUser>(token);

      setUser(userObj);
    }
  };

  const refreshToken = async (oldRefreshToken?: string) => {
    if (!oldRefreshToken) {
      oldRefreshToken = cookies.get('refreshToken');
    }

    const { data } = await externalApi.post('users/v1/users/refresh/', {
      refreshToken: oldRefreshToken,
    });

    // TODO: move to reusable function
    const { token, refreshBefore, refreshToken: incomingRefreshToken } = data;
    const expires = new Date(refreshBefore);
    const week = new Date();

    week.setDate(week.getDate() + 7);
    if (token) {
      cookies.set('token', token, { expires, path: '/' });
      cookies.set('refreshToken', incomingRefreshToken, {
        expires: week,
        path: '/',
      });
      internalApi.defaults.headers.Authorization = `Bearer ${token}`;
      externalApi.defaults.headers.Authorization = `Bearer ${token}`;
      proxyApi.defaults.headers.Authorization = `Bearer ${token}`;
      const userObj = jwtDecode<IUser>(token);

      setUser(userObj);
    }
  };

  const expiredToken = (token: string) => {
    const expires = new Date(jwtDecode<IUser>(token).exp.date).getTime();
    const now = new Date().getTime();

    // TODO: add a buffer in here? 10 minutes or so
    return expires <= now;
  };

  const isAuthenticated = useCallback(() => {
    const token = cookies.get('token');

    return !expiredToken(token);
  }, []);

  const hasRdbAccess = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) =>
      [
        'rdbadmin',
        'rdb1',
        'rdb2',
        'rdb3',
        'rdb4',
        'rdb5',
        'administrator',
        'developer',
      ].includes(role),
    );
  }, [user]);

  const isTester = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) => ['developer', 'tester'].includes(role));
  }, [user]);

  const isDeveloper = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) => ['developer'].includes(role));
  }, [user]);

  const hasArticleEditAccess = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) =>
      ['editor', 'author', 'administrator'].includes(role),
    );
  }, [user]);

  const canEditArticle = useCallback(
    (siteKey: number) => {
      if (!user?.rg) {
        return false;
      }

      const adminRoles = ['administrator', 'developer', 'desk_editor'];

      if (user.rg.some((role) => adminRoles.includes(role))) {
        return true;
      }

      return user.rg.some((userRole) =>
        editorRoles.some(
          (editorRole) =>
            editorRole.role === userRole && editorRole.site.includes(siteKey),
        ),
      );
    },
    [user],
  );

  const hasRdbLevel5Access = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) => ['rdb5'].includes(role));
  }, [user]);

  const isVerified = useCallback(
    (playerKey?: number | null) => {
      if (!user || !playerKey) {
        return false;
      }

      return (
        user.pk === String(playerKey) || (user.pks ?? []).includes(playerKey)
      );
    },
    [user],
  );

  const isVerifiedPlayer = useCallback(
    (playerKey?: string | number) => {
      if (!user || !playerKey) {
        return false;
      }

      return user?.pk === playerKey.toString();
    },
    [user],
  );

  const hasRdbLevel4Access = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) =>
      ['rdb5', 'rdb4', 'administrator', 'developer'].includes(role),
    );
  }, [user]);

  const hasRdbLevel3Access = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) =>
      ['rdb5', 'rdb4', 'rdb3', 'administrator', 'developer'].includes(role),
    );
  }, [user]);

  const hasRdbLevel2Access = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) =>
      ['rdb5', 'rdb4', 'rdb3', 'rdb2', 'administrator', 'developer'].includes(
        role,
      ),
    );
  }, [user]);
  const hasAccountingAdmin = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) =>
      ['accounting_admin', 'developer'].includes(role),
    );
  }, [user]);

  const hasPublisherAdmin = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) =>
      ['admin_publisher_dashboard', 'developer'].includes(role),
    );
  }, [user]);

  const hasShowsAdmin = useCallback(() => {
    if (!user?.rg) {
      return false;
    }

    return user?.rg.some((role) =>
      ['shows_admin', 'adminstrator', 'developer'].includes(role),
    );
  }, [user]);

  const logout = (redirectUrl?: string) => {
    try {
      window.tp?.pianoId?.logout();
    } catch (error) {
      console.error(error);
    }

    cookies.remove('token');
    document.cookie = 'token=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    cookies.remove('refreshToken');
    document.cookie =
      'refreshToken=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    // remove xenforo
    cookies.remove('xf_user');
    document.cookie = 'xf_user=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    cookies.remove('xf_session');
    document.cookie =
      'xf_session=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    cookies.remove('xf_csrf');
    document.cookie = 'xf_csrf=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    cookies.remove('xf_session_admin');
    document.cookie =
      'xf_session_admin=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    cookies.remove('xf_dbWriteForced');
    document.cookie =
      'xf_dbWriteForced=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    setUser(initialUser);
    if (redirectUrl) {
      Router.push(redirectUrl);
    } else {
      Router.push('/');
    }
  };
  const contextValue = useMemo(
    () => ({
      user,
      login,
      logout,
      setUser,
      isAuthenticated,
      refreshToken,
      setUserToken,
      hasRdbAccess,
      isVerified,
      isVerifiedPlayer,
      hasRdbLevel5Access,
      hasRdbLevel4Access,
      hasRdbLevel3Access,
      hasRdbLevel2Access,
      hasAccountingAdmin,
      hasPublisherAdmin,
      hasShowsAdmin,
      hasArticleEditAccess,
      canEditArticle,
      isDeveloper,
      isTester,
    }),
    [
      user,
      login,
      isAuthenticated,
      hasRdbAccess,
      isVerified,
      isVerifiedPlayer,
      hasRdbLevel5Access,
      hasRdbLevel4Access,
      hasRdbLevel3Access,
      hasRdbLevel2Access,
      hasAccountingAdmin,
      hasPublisherAdmin,
      hasShowsAdmin,
      hasArticleEditAccess,
      canEditArticle,
      isDeveloper,
      isTester,
    ],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  return context;
};

export { useAuth };
