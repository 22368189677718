import { SVGProps } from 'react';

const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 17 17"
    fill={props.fill ?? '#000000'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 12.232a4.04 4.04 0 0 1-4.036 4.037H4.536A4.04 4.04 0 0 1 .5 12.232V4.305A4.04 4.04 0 0 1 4.536.269h7.928A4.04 4.04 0 0 1 16.5 4.305v7.927ZM12.464 2.01H4.536a2.297 2.297 0 0 0-2.295 2.295v7.927a2.298 2.298 0 0 0 2.295 2.296h7.928a2.298 2.298 0 0 0 2.295-2.296V4.305a2.298 2.298 0 0 0-2.295-2.295ZM8.5 12.39a4.125 4.125 0 0 1-4.12-4.121A4.125 4.125 0 0 1 8.5 4.148a4.125 4.125 0 0 1 4.12 4.12A4.126 4.126 0 0 1 8.5 12.39ZM12.753 5a.976.976 0 0 1 0-1.95.976.976 0 0 1 0 1.95ZM6.12 8.268A2.382 2.382 0 0 1 8.5 5.889a2.382 2.382 0 0 1 2.38 2.379 2.382 2.382 0 0 1-2.38 2.38 2.383 2.383 0 0 1-2.38-2.38Z"
      // fill="#DCE1E4"
      fill={props.fill}
    />
  </svg>
);

export default SvgInstagram;
