export const CloseButton = () => {
  return (
    <svg
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#DCE1E4" height="20" rx="10" width="20" y="0.5" />
      <path
        d="M14 7.30571L13.1943 6.5L10 9.69429L6.80571 6.5L6 7.30571L9.19429 10.5L6 13.6943L6.80571 14.5L10 11.3057L13.1943 14.5L14 13.6943L10.8057 10.5L14 7.30571Z"
        fill="#1D252C"
      />
    </svg>
  );
};
