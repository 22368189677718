import {
  PublicOpenSearchResponse,
  V1PeopleAdminSearchListParams,
  v1PeopleSearchList,
  V1PeopleSearchListParams,
} from '@on3/api';
import type { AutoCompleteProps } from '@on3/ui-lib/src/components/AutoComplete/AutoComplete';
import {
  AutoComplete,
  type OptionType,
} from '@on3/ui-lib/src/components/AutoComplete/AutoComplete';
import { debounce } from '@on3/ui-lib/src/utils/debounce';
import { externalApi } from '@on3/ui-lib/utils/api';
import { useCallback, useState } from 'react';

interface PersonAutoCompleteProps
  extends Omit<AutoCompleteProps, 'options' | 'onSearch' | 'onChange'> {
  name: string;
  onChange: (value: OptionType[], field?: string) => void;
  optionTemplate?: (org: PublicOpenSearchResponse) => OptionType;
  searchParams?: V1PeopleAdminSearchListParams;
}

export const PersonAutoComplete = ({
  label = 'Person',
  limit = 1,
  name,
  onChange,
  optionTemplate,
  searchParams = {},
  ...props
}: PersonAutoCompleteProps) => {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Memoize the onChange handler
  const handleOnChange = useCallback(
    (value: OptionType[]) => onChange(value, name),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSearchChange = debounce((value: string) => {
    if (value.length < 3) {
      setOptions([]);

      return;
    }

    setLoading(true);
    handleSearch(value);
  }, 500);

  const handleSearch = async (term: string) => {
    const query = {
      ...searchParams,
      searchText: term,
    } as V1PeopleSearchListParams;

    try {
      const response = await v1PeopleSearchList(externalApi, query);

      if (!response.list) {
        console.error('Failed to fetch person results');

        return;
      }

      setOptions(
        response.list.map((person) =>
          optionTemplate
            ? optionTemplate(person)
            : {
                key: person.key,
                label: (person.name as string) ?? '',
                value: person.key,
              },
        ),
      );
    } catch (e) {
      console.error('Error fetching person:', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AutoComplete
      {...props}
      label={label}
      limit={limit}
      loading={loading}
      onChange={handleOnChange}
      onSearch={handleSearchChange}
      options={options}
    />
  );
};
