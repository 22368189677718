import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface AdminEpisodeRes {
  asset?: AssetResponse;
  /** @format int64 */
  assetKey?: number | null;
  description?: string | null;
  /** @format int32 */
  duration: number;
  /** @format uri */
  filePath: string;
  /** @format int64 */
  key: number;
  meta?: EpisodeMetaRes;
  name?: string;
  /** @format date-time */
  publishDate: string;
  /** @format int64 */
  showKey: number;
  type: EpisodeTypes;
  uid?: string;
}

export interface AdminEpisodeResPagedData {
  list?: AdminEpisodeRes[];
  pagination?: Pagination;
}

export interface AdminShowRes {
  appleMeta?: AppleShowMetaRes;
  asset?: AssetResponse;
  /** @format int64 */
  assetKey?: number | null;
  description?: string | null;
  external: boolean;
  featured: boolean;
  hosts?: SimpleUserResponse[];
  iHeartRadioMeta?: IHeartRadioShowMetaRes;
  jwPlayerMeta?: JwPlayerShowMetaRes;
  /** @format int64 */
  key: number;
  megaphoneMeta?: MegaphoneShowMetaRes;
  name?: string;
  national: boolean;
  /** @format int32 */
  order: number;
  shortName?: string | null;
  simplecastMeta?: SimplecastShowMetaRes;
  site?: SimpleSiteResponseRecord;
  /** @format int32 */
  siteKey?: number | null;
  spotifyMeta?: SpotifyShowMetaRes;
  youTubeMeta?: YouTubeShowMetaRes;
}

export interface AdminShowResPagedData {
  list?: AdminShowRes[];
  pagination?: Pagination;
}

export interface AppleShowMetaRes {
  url?: string | null;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface AuthorResponseRecord {
  author?: UserProfileResponseRecord;
  yoastHead?: string | null;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export enum CategoryTypes {
  Unknown = 'Unknown',
  Home = 'Home',
  Sport = 'Sport',
  Recruiting = 'Recruiting',
  Newsletter = 'Newsletter',
  HighSchool = 'HighSchool',
}

export interface CommunityForumResponse {
  description: string;
  primaryForum?: ForumLinkResponse;
  title: string;
  url: string;
}

export interface DeleteResponse {
  clientError: boolean;
  errors?: string[];
  success: boolean;
}

export interface EpisodeMetaRes {
  imageSrc?: string | null;
  /** @format date-time */
  lastUpdated?: string | null;
}

export enum EpisodeTypes {
  Unknown = 'Unknown',
  Podcast = 'Podcast',
  Video = 'Video',
}

export enum ExternalStore {
  Apple = 'Apple',
  Google = 'Google',
  Amazon = 'Amazon',
}

export interface FeaturedWriterResponse {
  /** @format int32 */
  key: number;
  /** @format int32 */
  order: number;
  site?: SimpleSiteResponseRecord;
  /** @format int32 */
  siteKey: number;
  user?: SimpleUserResponse;
  /** @format int64 */
  userKey: number;
}

export interface FeaturedWriterResponsePagedData {
  list?: FeaturedWriterResponse[];
  pagination?: Pagination;
}

export interface ForumLinkResponse {
  title: string;
  url: string;
}

export interface IHeartRadioShowMetaRes {
  podcastId?: string | null;
}

export interface JwPlayerShowMetaRes {
  playlistId?: string | null;
}

export interface KeyUrlAsset {
  fileSystem?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  path?: string;
  slug?: string | null;
  type?: string;
  url?: string;
}

export interface KeyUrlBase {
  fullName?: string;
  /** @format int64 */
  key: number;
  slug?: string | null;
  url?: string | null;
}

export interface LatestForumPostsResponse {
  posts?: ThreadLatestPostResponse[];
  primaryForum?: ForumLinkResponse;
  title: string;
  url: string;
}

export interface MegaphoneShowMetaRes {
  feedId?: string | null;
}

export interface MenuResponse {
  children?: MenuResponse[] | null;
  /** @format int64 */
  id: number;
  /** @format int64 */
  key: number;
  /** @format int32 */
  order?: number | null;
  /** @format int64 */
  parent: number;
  target?: string | null;
  title?: string | null;
  titleAttribute?: string | null;
  url?: string | null;
}

export interface MobilePlanResponse {
  enabled: boolean;
  /** @format int32 */
  key: number;
  /** @minLength 1 */
  productId: string;
  /** @format int32 */
  siteKey: number;
  store: ExternalStore;
  type: PlanType;
}

export interface PageResponse {
  body: string;
  /** @format int64 */
  key: number;
  title?: string | null;
  /** @format date-time */
  updatedAt: string;
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export enum PlanType {
  Any = 'Any',
  Monthly = 'Monthly',
  Annual = 'Annual',
  Upgrade = 'Upgrade',
  Save = 'Save',
}

export interface RenSiteCategoryReq {
  /** @format int64 */
  categoryKey: number;
  categoryType: CategoryTypes;
  /** @format int32 */
  key?: number | null;
  /** @format int32 */
  siteKey: number;
  /** @format int32 */
  sportKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  url: string;
}

export interface RenovateAppleShowMetaReq {
  url?: string | null;
}

export interface RenovateEpisodeReq {
  /** @format int64 */
  assetKey?: number | null;
  description?: string | null;
  /** @format int32 */
  duration: number;
  /** @format uri */
  filePath: string;
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /** @format date-time */
  publishDate: string;
  /** @format int64 */
  showKey: number;
  type: EpisodeTypes;
  /**
   * @minLength 0
   * @maxLength 255
   */
  uid: string;
}

export interface RenovateFeaturedWriterRequest {
  /** @format int32 */
  key?: number | null;
  /** @format int32 */
  order: number;
  /** @format int32 */
  siteKey: number;
  /** @format int64 */
  userKey: number;
}

export interface RenovateIHeartRadioShowMetaReq {
  podcastId?: string | null;
}

export interface RenovateJwPlayerShowMetaReq {
  playlistId?: string | null;
}

export interface RenovateMegaphoneShowMetaReq {
  feedId?: string | null;
}

export interface RenovateShowReq {
  appleMeta?: RenovateAppleShowMetaReq;
  /** @format int64 */
  assetKey?: number | null;
  description?: string | null;
  external: boolean;
  featured: boolean;
  hosts?: number[] | null;
  iHeartRadioMeta?: RenovateIHeartRadioShowMetaReq;
  jwPlayerMeta?: RenovateJwPlayerShowMetaReq;
  /** @format int64 */
  key?: number | null;
  megaphoneMeta?: RenovateMegaphoneShowMetaReq;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name?: string;
  national: boolean;
  /** @format int32 */
  order: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  shortName?: string | null;
  simplecastMeta?: RenovateSimplecastShowMetaReq;
  /** @format int32 */
  siteKey?: number | null;
  spotifyMeta?: RenovateSpotifyShowMetaReq;
  youTubeMeta?: RenovateYouTubeShowMetaReq;
}

export interface RenovateSimplecastShowMetaReq {
  podcastId?: string | null;
}

export interface RenovateSpotifyShowMetaReq {
  url?: string | null;
}

export interface RenovateTrendingListItemRequest {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  displayOrder: number;
  /**
   * @minLength 1
   * @maxLength 100
   */
  headline: string;
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  subject: string;
  type?: TrendingListItemType;
  /**
   * @format uri
   * @minLength 1
   * @maxLength 255
   */
  url: string;
}

export interface RenovateTrendingListRequest {
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
}

export interface RenovateYouTubeShowMetaReq {
  url?: string | null;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface SeedMetricsForSitesReq {
  /** @format date-time */
  endDate: string;
  siteKeys: number[];
}

export interface SimpleImageResponseRecord {
  /** @format int64 */
  key: number;
  url?: string | null;
}

export interface SimpleSiteResponseRecord {
  allowSubs: boolean;
  alternateAvatar?: AssetResponse;
  /** @format int64 */
  alternateAvatarKey?: number | null;
  alternateLogo?: AssetResponse;
  /** @format int64 */
  alternateLogoKey?: number | null;
  amazonAffiliateLink?: string | null;
  /** @format int64 */
  categoryKey?: number | null;
  defaultAsset?: AssetResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAvatar?: AssetResponse;
  /** @format int64 */
  defaultAvatarKey?: number | null;
  /** @format int64 */
  defaultTrendingListKey?: number | null;
  facebookProfile?: string | null;
  /** @format int32 */
  forumKey?: number | null;
  gTagId?: string | null;
  googleAnalyticsProjectId?: string | null;
  inIndex: boolean;
  instagramProfile?: string | null;
  isChannel: boolean;
  isNational: boolean;
  isTeam: boolean;
  /** @format int32 */
  key: number;
  keyPersons?: SimpleUserResponse[] | null;
  linkColor?: string | null;
  live: boolean;
  /** @format int32 */
  menuKey?: number | null;
  name?: string | null;
  /** @format int32 */
  organizationKey?: number | null;
  primaryColor?: string | null;
  /** @format int32 */
  primaryForumId?: number | null;
  resourceId?: string | null;
  secondaryColor?: string | null;
  /** @format int32 */
  siblingSiteKey?: number | null;
  slug?: string | null;
  subscribeText?: string | null;
  tagLine?: string | null;
  template: SiteTemplate;
  templateId?: string | null;
  tertiaryColor?: string | null;
  twitterHandle?: string | null;
  type: SiteType;
  url?: string | null;
  youTubeChannelId?: string | null;
  youtubeProfile?: string | null;
}

export interface SimpleUserResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  login?: string | null;
  niceName?: string | null;
}

export interface SimplecastShowMetaRes {
  podcastId?: string | null;
}

export interface SiteCategoryResponse {
  /** @format int64 */
  categoryKey: number;
  categoryName?: string | null;
  categoryType: CategoryTypes;
  /** @format int32 */
  key: number;
  /** @format int32 */
  siteKey: number;
  /** @format int32 */
  sportKey?: number | null;
  sportName?: string | null;
  url?: string | null;
}

export interface SiteCategoryResponseSimpleSiteResponseRecordRelatedPagedData {
  list?: SiteCategoryResponse[];
  pagination?: Pagination;
  relatedModel?: SimpleSiteResponseRecord;
}

export interface SiteConferenceResponse {
  abbreviation?: string | null;
  asset?: KeyUrlBase;
  /** @format int64 */
  assetKey?: number | null;
  assetRes?: AssetResponse;
  /** @format int32 */
  key: number;
  name?: string | null;
}

export interface SiteMetricSeededRes {
  /** @format int32 */
  metricsSeeded: number;
  site: SimpleSiteResponseRecord;
}

export interface SiteOrganizationResponse {
  abbreviation?: string | null;
  defaultAsset?: KeyUrlAsset;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAssetRes?: AssetResponse;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
}

export interface SiteResponse {
  allowSubs: boolean;
  alternateAvatar?: AssetResponse;
  alternateLogo?: AssetResponse;
  amazonAffiliateLink?: string | null;
  /** @format int64 */
  categoryKey?: number | null;
  conference?: SiteConferenceResponse;
  defaultAsset?: KeyUrlBase;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAssetRes?: AssetResponse;
  defaultAvatar?: AssetResponse;
  /** @format int64 */
  defaultTrendingListKey?: number | null;
  facebookProfile?: string | null;
  forum?: ForumLinkResponse;
  /** @format int32 */
  forumKey?: number | null;
  googleAnalyticsProjectId?: string | null;
  gtagId?: string | null;
  inIndex: boolean;
  instagramProfile?: string | null;
  isChannel: boolean;
  isNational: boolean;
  isTeam: boolean;
  /** @format int32 */
  key: number;
  linkColor?: string | null;
  live: boolean;
  /** @format int32 */
  menuKey?: number | null;
  name?: string | null;
  /** @format int32 */
  newsletterListId?: number | null;
  organization?: SiteOrganizationResponse;
  /** @format int32 */
  organizationKey?: number | null;
  primaryColor?: string | null;
  primaryForum?: ForumLinkResponse;
  /** @format int32 */
  primaryForumId?: number | null;
  resourceId?: string | null;
  secondaryColor?: string | null;
  /** @format int32 */
  siblingSiteKey?: number | null;
  siteCategories?: SiteCategoryResponse[] | null;
  siteName?: string | null;
  slug?: string | null;
  subscribeText?: string | null;
  tagLine?: string | null;
  template: SiteTemplate;
  templateId?: string | null;
  tertiaryColor?: string | null;
  twitterHandle?: string | null;
  type: SiteType;
  url?: string | null;
  youTubeChannelId?: string | null;
  youtubeProfile?: string | null;
}

export enum SiteTemplate {
  Feed = 'Feed',
  Traditional = 'Traditional',
}

export enum SiteType {
  National = 'National',
  Channel = 'Channel',
  Team = 'Team',
}

export interface SlimSiteResponse {
  allowSubs: boolean;
  /** @format int32 */
  key: number;
  live: boolean;
  /** @format int32 */
  menuKey?: number | null;
  name?: string | null;
  primaryForum?: ForumLinkResponse;
  /** @format int32 */
  siblingSiteKey?: number | null;
  url?: string | null;
}

export interface SpotifyShowMetaRes {
  url?: string | null;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface SyncEpisodesForShowReq {
  /** @example false */
  byPassDelay: boolean;
  /** @example false */
  fullSync: boolean;
  /**
   * If `true`, the current episodes are deleted before syncing and forces `fullSync` to be `true`.
   * @example false
   */
  reset: boolean;
}

export interface SyncEpisodesReq {
  /** @example false */
  byPassDelay: boolean;
  /** @example false */
  fullSync: boolean;
}

export interface SyncedEpisodesRes {
  /**
   * In most cases, the total number of episodes for the show. If iHeartRadio, then it's the number of episodes
   * we retrieved before stopping.
   * @format int32
   */
  episodesFound: number;
  /**
   * The number of episodes created/updated. This can be 0 for a successful sync if there are no new episodes.
   * @format int32
   */
  episodesSynced: number;
  /** The service used to sync episodes (Simplecast, Apple, iHeartRadio, etc) or null if unable to sync. */
  platform: string | null;
  /** @format int64 */
  showKey: number;
  /**
   * The number of items skipped because they did not have a guid/id.
   * @format int32
   */
  skippedForMissingGuid: number;
  /**
   * The number of episodes skipped because they do not have a link to the audio file. This can happen
   * @format int32
   */
  skippedForMissingLink: number;
  /**
   * The number of items skipped because they had an "itunes:type" that was not null or "full".
   * @format int32
   */
  skippedForWrongType: number;
}

export interface ThreadLatestPostResponse {
  lastPostAuthorUsername: string;
  /** @format int32 */
  lastPostDate: number;
  /** @format int32 */
  replyCount: number;
  threadAuthorUsername: string;
  /** @format int32 */
  threadDate: number;
  title: string;
  url: string;
  /** @format int32 */
  viewCount: number;
}

export interface TrendingListItemResponse {
  /** @format int32 */
  displayOrder: number;
  headline: string;
  /** @format int64 */
  key: number;
  subject: string;
  /** @format int64 */
  trendingListKey: number;
  type?: TrendingListItemType;
  url: string;
}

export interface TrendingListItemResponsePagedData {
  list?: TrendingListItemResponse[];
  pagination?: Pagination;
}

export interface TrendingListItemResponseTrendingListResponseRelatedPagedData {
  list?: TrendingListItemResponse[];
  pagination?: Pagination;
  relatedModel?: TrendingListResponse;
}

export enum TrendingListItemType {
  Unknown = 'Unknown',
  Breaking = 'Breaking',
  Hot = 'Hot',
  Live = 'Live',
  New = 'New',
  Trending = 'Trending',
}

export interface TrendingListResponse {
  /** @format int64 */
  key: number;
  name: string;
  sites?: number[];
}

export interface TrendingListResponsePagedData {
  list?: TrendingListResponse[];
  pagination?: Pagination;
}

export interface UpdateYouTubeResponse {
  /** @format int32 */
  quotaUsed: number;
}

export interface UserProfileResponseRecord {
  bio?: string | null;
  instagramHandle?: string | null;
  jobTitle?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  niceName?: string | null;
  profileAsset?: AssetResponse;
  profilePicture?: SimpleImageResponseRecord;
  twitterHandle?: string | null;
  youtubeUrl?: string | null;
}

export interface V1CategoriesWritersDetailParams {
  /** @format int64 */
  categoryKey: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
}

export interface V1EpisodesListParams {
  /** @format int32 */
  items?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int64 */
  showKey: number;
}

export interface V1FeaturedWritersListParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  siteKey?: number;
}

export interface V1MobilePlansListParams {
  store: ExternalStore;
}

export interface V1PagesListParams {
  pageSlug: string;
  /** @format int32 */
  siteKey?: number;
  siteSlug?: string;
}

export interface V1ShowsListParams {
  /** @format int32 */
  items?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  siteKey?: number;
}

export interface V1SitesDetailParams {
  /** @default true */
  includeCategories?: boolean;
  /** @format int32 */
  key: number;
}

export interface V1SitesListParams {
  resourceId?: string;
  slug?: string;
  type?: SiteType;
}

export interface V1SitesMobilePlansDetailParams {
  /** @format int32 */
  key: number;
  store: ExternalStore;
}

export interface V1SitesPubMetricsExportCreateParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey?: number;
  /** @format date-time */
  startDate: string;
}

export interface V1SitesPubMetricsImportUpdatePayload {
  /** @format binary */
  csvFile: File;
}

export interface V1SitesWriterArticleBreakdownsCreateParams {
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  startDate?: string;
}

export interface V1TrendingListsItemsDetailParams {
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int64 */
  trendingListKey: number;
}

export type V1TrendingListsItemsUpdatePayload =
  RenovateTrendingListItemRequest[];

export interface V1TrendingListsListParams {
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  siteKey?: number;
}

export interface ValidationProblemDetails {
  detail?: string | null;
  errors?: Record<string, string[]>;
  instance?: string | null;
  /** @format int32 */
  status?: number | null;
  title?: string | null;
  type?: string | null;
  [key: string]: any;
}

export interface XfThreadStatsRes {
  /** @format int32 */
  firstPostReactionCount: number;
  /** @format int32 */
  replyCount: number;
  /** @format int32 */
  threadId: number;
  title: string;
  /** @format int32 */
  totalReactions: number;
  /** @format int32 */
  uniqueVisitors: number;
  /** @format int32 */
  viewCount: number;
}

export interface YouTubeShowMetaRes {
  url?: string | null;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags Author
 * @name V1AuthorsDetail
 * @summary Gets the author information for a author slug
 * @request GET:/sites/v1/authors/{author}
 */
export const v1AuthorsDetail = (
  client: AxiosInstance,
  author: string,
  params: RequestParams = {},
) =>
  client
    .request<AuthorResponseRecord>({
      url: `/sites/v1/authors/${author}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Author
 * @name V1CategoriesWritersDetail
 * @summary Gets the authors for a site categoryKey
 * @request GET:/sites/v1/categories/{categoryKey}/writers
 */
export const v1CategoriesWritersDetail = (
  client: AxiosInstance,
  { categoryKey, ...query }: V1CategoriesWritersDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<UserProfileResponseRecord[]>({
      url: `/sites/v1/categories/${categoryKey}/writers`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Episodes
 * @name V1EpisodesDetail
 * @summary  (Roles: developer, shows_admin)
 * @request GET:/sites/v1/episodes/{key}
 * @secure
 */
export const v1EpisodesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminEpisodeRes>({
      url: `/sites/v1/episodes/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Episodes
 * @name V1EpisodesList
 * @summary  (Roles: developer, shows_admin)
 * @request GET:/sites/v1/episodes
 * @secure
 */
export const v1EpisodesList = (
  client: AxiosInstance,
  query: V1EpisodesListParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminEpisodeResPagedData>({
      url: `/sites/v1/episodes`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Episodes
 * @name V1EpisodesUpdate
 * @summary  (Roles: developer, shows_admin)
 * @request PUT:/sites/v1/episodes
 * @secure
 */
export const v1EpisodesUpdate = (
  client: AxiosInstance,
  data: RenovateEpisodeReq,
  params: RequestParams = {},
) =>
  client
    .request<AdminEpisodeRes>({
      url: `/sites/v1/episodes`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Author
 * @name V1FeaturedWritersDelete
 * @summary  (Roles: developer)
 * @request DELETE:/sites/v1/featured-writers/{key}
 * @secure
 */
export const v1FeaturedWritersDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<FeaturedWriterResponse>({
      url: `/sites/v1/featured-writers/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Author
 * @name V1FeaturedWritersDetail
 * @summary Gets the featured writers for a site categoryKey
 * @request GET:/sites/v1/{siteKey}/featuredWriters
 */
export const v1FeaturedWritersDetail = (
  client: AxiosInstance,
  siteKey: number,
  params: RequestParams = {},
) =>
  client
    .request<UserProfileResponseRecord[]>({
      url: `/sites/v1/${siteKey}/featuredWriters`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Author
 * @name V1FeaturedWritersList
 * @summary  (Roles: developer)
 * @request GET:/sites/v1/featured-writers
 * @secure
 */
export const v1FeaturedWritersList = (
  client: AxiosInstance,
  query: V1FeaturedWritersListParams,
  params: RequestParams = {},
) =>
  client
    .request<FeaturedWriterResponsePagedData>({
      url: `/sites/v1/featured-writers`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Author
 * @name V1FeaturedWritersRenovateUpdate
 * @summary  (Roles: developer)
 * @request PUT:/sites/v1/featured-writers/renovate
 * @secure
 */
export const v1FeaturedWritersRenovateUpdate = (
  client: AxiosInstance,
  data: RenovateFeaturedWriterRequest,
  params: RequestParams = {},
) =>
  client
    .request<FeaturedWriterResponse>({
      url: `/sites/v1/featured-writers/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Menu
 * @name V1MenusDetail
 * @request GET:/sites/v1/menus/{key}
 */
export const v1MenusDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<MenuResponse[]>({
      url: `/sites/v1/menus/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Pages
 * @name V1PagesList
 * @request GET:/sites/v1/pages
 */
export const v1PagesList = (
  client: AxiosInstance,
  query: V1PagesListParams,
  params: RequestParams = {},
) =>
  client
    .request<PageResponse>({
      url: `/sites/v1/pages`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Shows
 * @name V1ShowsDetail
 * @summary  (Roles: developer, shows_admin)
 * @request GET:/sites/v1/shows/{key}
 * @secure
 */
export const v1ShowsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminShowRes>({
      url: `/sites/v1/shows/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Episodes
 * @name V1ShowsEpisodesSyncCreate
 * @summary  (Roles: developer, shows_admin)
 * @request POST:/sites/v1/shows/{key}/episodes/sync
 * @secure
 */
export const v1ShowsEpisodesSyncCreate = (
  client: AxiosInstance,
  key: number,
  data: SyncEpisodesForShowReq,
  params: RequestParams = {},
) =>
  client
    .request<SyncedEpisodesRes>({
      url: `/sites/v1/shows/${key}/episodes/sync`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Episodes
 * @name V1ShowsEpisodesSyncLocCreate
 * @request POST:/sites/v1/shows/{key}/episodes/sync-loc
 */
export const v1ShowsEpisodesSyncLocCreate = (
  client: AxiosInstance,
  key: number,
  data: SyncEpisodesForShowReq,
  params: RequestParams = {},
) =>
  client
    .request<SyncedEpisodesRes>({
      url: `/sites/v1/shows/${key}/episodes/sync-loc`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Shows
 * @name V1ShowsList
 * @summary  (Roles: developer, shows_admin)
 * @request GET:/sites/v1/shows
 * @secure
 */
export const v1ShowsList = (
  client: AxiosInstance,
  query: V1ShowsListParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminShowResPagedData>({
      url: `/sites/v1/shows`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Episodes
 * @name V1ShowsSyncEpisodesLocCreate
 * @request POST:/sites/v1/shows/sync-episodes-loc
 */
export const v1ShowsSyncEpisodesLocCreate = (
  client: AxiosInstance,
  data: SyncEpisodesReq,
  params: RequestParams = {},
) =>
  client
    .request<SyncedEpisodesRes[]>({
      url: `/sites/v1/shows/sync-episodes-loc`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Shows
 * @name V1ShowsUpdate
 * @summary  (Roles: developer, shows_admin)
 * @request PUT:/sites/v1/shows
 * @secure
 */
export const v1ShowsUpdate = (
  client: AxiosInstance,
  data: RenovateShowReq,
  params: RequestParams = {},
) =>
  client
    .request<AdminShowRes>({
      url: `/sites/v1/shows`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SiteCategory
 * @name V1SiteCategoriesDetail
 * @summary  (Roles: developer, rdb5, social_admin)
 * @request GET:/sites/v1/site-categories/{key}
 * @secure
 */
export const v1SiteCategoriesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<SiteCategoryResponse>({
      url: `/sites/v1/site-categories/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SiteCategory
 * @name V1SiteCategoriesRenovateUpdate
 * @summary  (Roles: developer)
 * @request PUT:/sites/v1/site-categories/renovate
 * @secure
 */
export const v1SiteCategoriesRenovateUpdate = (
  client: AxiosInstance,
  data: RenSiteCategoryReq,
  params: RequestParams = {},
) =>
  client
    .request<SiteCategoryResponse>({
      url: `/sites/v1/site-categories/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SiteCategory
 * @name V1SitesCategoriesDetail
 * @summary  (Roles: developer, rdb5, social_admin)
 * @request GET:/sites/v1/sites/{siteKey}/categories
 * @secure
 */
export const v1SitesCategoriesDetail = (
  client: AxiosInstance,
  siteKey: number,
  params: RequestParams = {},
) =>
  client
    .request<SiteCategoryResponseSimpleSiteResponseRecordRelatedPagedData>({
      url: `/sites/v1/sites/${siteKey}/categories`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V1SitesDetail
 * @request GET:/sites/v1/sites/{key}
 */
export const v1SitesDetail = (
  client: AxiosInstance,
  { key, ...query }: V1SitesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<SiteResponse>({
      url: `/sites/v1/sites/${key}`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Author
 * @name V1SitesFeaturedWritersDetail
 * @summary Gets the featured writers for a site categoryKey
 * @request GET:/sites/v1/sites/{siteKey}/featured-writers
 */
export const v1SitesFeaturedWritersDetail = (
  client: AxiosInstance,
  siteKey: number,
  params: RequestParams = {},
) =>
  client
    .request<UserProfileResponseRecord[]>({
      url: `/sites/v1/sites/${siteKey}/featured-writers`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Forum
 * @name V1SitesForumsCommunityDetail
 * @request GET:/sites/v1/sites/{siteKey}/forums/community
 */
export const v1SitesForumsCommunityDetail = (
  client: AxiosInstance,
  siteKey: number,
  params: RequestParams = {},
) =>
  client
    .request<CommunityForumResponse>({
      url: `/sites/v1/sites/${siteKey}/forums/community`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Forum
 * @name V1SitesForumsLatestPostsDetail
 * @request GET:/sites/v1/sites/{siteKey}/forums/latest-posts
 */
export const v1SitesForumsLatestPostsDetail = (
  client: AxiosInstance,
  siteKey: number,
  params: RequestParams = {},
) =>
  client
    .request<LatestForumPostsResponse>({
      url: `/sites/v1/sites/${siteKey}/forums/latest-posts`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V1SitesList
 * @request GET:/sites/v1/sites
 */
export const v1SitesList = (
  client: AxiosInstance,
  query: V1SitesListParams,
  params: RequestParams = {},
) =>
  client
    .request<SiteResponse[]>({
      url: `/sites/v1/sites`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Menu
 * @name V1SitesMenusDetail
 * @request GET:/sites/v1/sites/{key}/menus
 */
export const v1SitesMenusDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<MenuResponse[]>({
      url: `/sites/v1/sites/${key}/menus`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V1SitesMobilePlansDetail
 * @request GET:/sites/v1/sites/{key}/mobile-plans
 */
export const v1SitesMobilePlansDetail = (
  client: AxiosInstance,
  { key, ...query }: V1SitesMobilePlansDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<MobilePlanResponse[]>({
      url: `/sites/v1/sites/${key}/mobile-plans`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PubMetrics
 * @name V1SitesPubMetricsExportCreate
 * @summary  (Roles: developer, accounting_admin, admin_publisher_dashboard)
 * @request POST:/sites/v1/sites/pub-metrics/export
 * @secure
 */
export const v1SitesPubMetricsExportCreate = (
  client: AxiosInstance,
  query: V1SitesPubMetricsExportCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<File>({
      url: `/sites/v1/sites/pub-metrics/export`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PubMetrics
 * @name V1SitesPubMetricsImportUpdate
 * @summary  (Roles: developer, accounting_admin)
 * @request PUT:/sites/v1/sites/pub-metrics/import
 * @secure
 */
export const v1SitesPubMetricsImportUpdate = (
  client: AxiosInstance,
  data: V1SitesPubMetricsImportUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/sites/v1/sites/pub-metrics/import`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PubMetrics
 * @name V1SitesSeedMetricsCreate
 * @summary  (Roles: developer, accounting_admin)
 * @request POST:/sites/v1/sites/seed-metrics
 * @secure
 */
export const v1SitesSeedMetricsCreate = (
  client: AxiosInstance,
  data: SeedMetricsForSitesReq,
  params: RequestParams = {},
) =>
  client
    .request<SiteMetricSeededRes[]>({
      url: `/sites/v1/sites/seed-metrics`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PubMetrics
 * @name V1SitesSeedMetricsLocCreate
 * @request POST:/sites/v1/sites/seed-metrics-loc
 */
export const v1SitesSeedMetricsLocCreate = (
  client: AxiosInstance,
  data: SeedMetricsForSitesReq,
  params: RequestParams = {},
) =>
  client
    .request<SiteMetricSeededRes[]>({
      url: `/sites/v1/sites/seed-metrics-loc`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V1SitesSlimSitesList
 * @request GET:/sites/v1/sites/slim-sites
 */
export const v1SitesSlimSitesList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<SlimSiteResponse[]>({
      url: `/sites/v1/sites/slim-sites`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PubMetrics
 * @name V1SitesWriterArticleBreakdownsCreate
 * @summary  (Roles: developer, accounting_admin, admin_publisher_dashboard)
 * @request POST:/sites/v1/sites/writer-article-breakdowns
 * @secure
 */
export const v1SitesWriterArticleBreakdownsCreate = (
  client: AxiosInstance,
  query: V1SitesWriterArticleBreakdownsCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<File>({
      url: `/sites/v1/sites/writer-article-breakdowns`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TrendingList
 * @name V1TrendingListsDelete
 * @summary  (Roles: developer, administrator, trending_admin)
 * @request DELETE:/sites/v1/trending-lists/{trendingListKey}
 * @secure
 */
export const v1TrendingListsDelete = (
  client: AxiosInstance,
  trendingListKey: number,
  params: RequestParams = {},
) =>
  client
    .request<DeleteResponse>({
      url: `/sites/v1/trending-lists/${trendingListKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TrendingList
 * @name V1TrendingListsDetail
 * @summary  (Roles: developer, administrator, trending_admin)
 * @request GET:/sites/v1/trending-lists/{trendingListKey}
 * @secure
 */
export const v1TrendingListsDetail = (
  client: AxiosInstance,
  trendingListKey: number,
  params: RequestParams = {},
) =>
  client
    .request<TrendingListResponse>({
      url: `/sites/v1/trending-lists/${trendingListKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TrendingList
 * @name V1TrendingListsItemsDelete
 * @summary  (Roles: developer, administrator, trending_admin)
 * @request DELETE:/sites/v1/trending-lists/items/{trendingListItemKey}
 * @secure
 */
export const v1TrendingListsItemsDelete = (
  client: AxiosInstance,
  trendingListItemKey: number,
  params: RequestParams = {},
) =>
  client
    .request<DeleteResponse>({
      url: `/sites/v1/trending-lists/items/${trendingListItemKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TrendingList
 * @name V1TrendingListsItemsDetail
 * @summary  (Roles: developer, administrator, trending_admin)
 * @request GET:/sites/v1/trending-lists/{trendingListKey}/items
 * @secure
 */
export const v1TrendingListsItemsDetail = (
  client: AxiosInstance,
  { trendingListKey, ...query }: V1TrendingListsItemsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<TrendingListItemResponseTrendingListResponseRelatedPagedData>({
      url: `/sites/v1/trending-lists/${trendingListKey}/items`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TrendingList
 * @name V1TrendingListsItemsUpdate
 * @summary  (Roles: developer, administrator, trending_admin)
 * @request PUT:/sites/v1/trending-lists/{trendingListKey}/items
 * @secure
 */
export const v1TrendingListsItemsUpdate = (
  client: AxiosInstance,
  trendingListKey: number,
  data: V1TrendingListsItemsUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<TrendingListItemResponsePagedData>({
      url: `/sites/v1/trending-lists/${trendingListKey}/items`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TrendingList
 * @name V1TrendingListsList
 * @summary  (Roles: developer, administrator, trending_admin)
 * @request GET:/sites/v1/trending-lists
 * @secure
 */
export const v1TrendingListsList = (
  client: AxiosInstance,
  query: V1TrendingListsListParams,
  params: RequestParams = {},
) =>
  client
    .request<TrendingListResponsePagedData>({
      url: `/sites/v1/trending-lists`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TrendingList
 * @name V1TrendingListsUpdate
 * @summary  (Roles: developer, administrator, trending_admin)
 * @request PUT:/sites/v1/trending-lists
 * @secure
 */
export const v1TrendingListsUpdate = (
  client: AxiosInstance,
  data: RenovateTrendingListRequest,
  params: RequestParams = {},
) =>
  client
    .request<TrendingListResponse>({
      url: `/sites/v1/trending-lists`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags YouTube
 * @name V1YoutubeProcessChannelUpdateCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/sites/v1/youtube/process-channel-update
 * @secure
 */
export const v1YoutubeProcessChannelUpdateCreate = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<UpdateYouTubeResponse>({
      url: `/sites/v1/youtube/process-channel-update`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags YouTube
 * @name V1YoutubeProcessVideosUpdateCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/sites/v1/youtube/process-videos-update
 * @secure
 */
export const v1YoutubeProcessVideosUpdateCreate = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<UpdateYouTubeResponse>({
      url: `/sites/v1/youtube/process-videos-update`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Forum
 * @name V1ThreadsStatsDetail
 * @summary  (Roles: developer, rdb5, administrator)
 * @request GET:/xf/v1/threads/{threadId}/stats
 * @secure
 */
export const v1ThreadsStatsDetail = (
  client: AxiosInstance,
  threadId: number,
  params: RequestParams = {},
) =>
  client
    .request<XfThreadStatsRes>({
      url: `/xf/v1/threads/${threadId}/stats`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags MobilePlans
 * @name V1MobilePlansList
 * @request GET:/mobile-plans/v1/mobile-plans
 */
export const v1MobilePlansList = (
  client: AxiosInstance,
  query: V1MobilePlansListParams,
  params: RequestParams = {},
) =>
  client
    .request<MobilePlanResponse[]>({
      url: `/mobile-plans/v1/mobile-plans`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);
