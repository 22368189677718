import { On3Recruit } from '@on3/ui-lib/components/Svg/On3Recruit';
import { SpinnerThird } from '@on3/ui-lib/components/Svg/SpinnerThird';
import { useAuth } from '@on3/ui-lib/index';
import { useEffect, useState } from 'react';

import styles from './RecruitsReceipt.module.scss';

export const RecruitsReceiptModal = ({ navigate }: { navigate?: string }) => {
  const { user } = useAuth();

  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (navigate) {
      setRedirecting(true);

      setTimeout(() => {
        window.location.href = navigate;
      }, 1000);
    }
  }, [navigate]);

  return (
    <div className={styles.modal}>
      <figure className={styles.banner}>
        <On3Recruit fill="#fff" variant="white" />
      </figure>
      <div className={styles.content}>
        <header>
          <h2>You&apos;re In—Thank You!</h2>
          <p>
            We&apos;re thrilled you&apos;ve chosen On3 to manage your athlete
            profile and explore recruiting possibilities. Sit tight—we&apos;re
            redirecting you to get started.
          </p>
        </header>
        <p>A confirmation email has been sent to {user?.e}.</p>
        <div>
          <div className={styles.link}>
            <SpinnerThird />
            {redirecting ? 'Redirecting...' : 'Please Wait...'}
          </div>
        </div>
      </div>
    </div>
  );
};
